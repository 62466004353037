import { logToTimber } from '@web-3d-tool/shared-logic';

export const logEventToTimber = ({ eventType, eventSource }) => {
  let eventName = '';
  let action = '';

  if (eventType === 'zoom') {
    eventName = eventSource === 'niri' ? 'NIRI Panel - Zoom-web viewer' : 'IOC Panel - Zoom-web viewer';
    action = 'Zoom';
  } else {
    eventName = eventSource === 'niri' ? 'NIRI Panel - Pan-web viewer ' : 'IOC Panel - Pan-web viewer';
    action = 'Pan';
  }

  const module = eventSource === 'niri' ? 'NIRI Panel' : 'IOC Panel';
  const type = 'Panel';

  logToTimber({
    timberData: {
      eventName,
      action,
      module,
      type
    }
  });
};
