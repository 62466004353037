// polyfills must be imported first
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { logger, utils } from '@web-3d-tool/shared-logic';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@web-3d-tool/redux-logic';

import * as serviceWorker from './serviceWorker';
import App from './App';
import './index.css';
import './colors.css';
import * as logicToWindow from './logic-to-window';

const store = configureStore();

if (navigator.webdriver) {
  logicToWindow.register({ getState: store.getState });
}

logger.time('AppStarted');
logger
  .info(`App started`)
  .data({ module: 'root' })
  .to(['console'])
  .end();
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
utils.getEnv() === 'scanner' ? serviceWorker.register() : serviceWorker.unregister();
