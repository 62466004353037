export const Environments = {
  EUP: 'eup',
  SCANNER: 'scanner'
};

export const EnvironmentParams = {
  EUP_MODEL_URL_PREFIX: '/mvc/orderfiles/GetItrFile',
  EUP_NIRI_URL_PREFIX: '/mvc/orderfiles/GetEvxFile',
  EUP_IS_MODEL_EXIST_URL_PREFIX: '/mvc/orderfiles/IsItrFileExists',
  NIRI_ASSET_REFFERENCE_TYPE_NUMBER: '15'
};
