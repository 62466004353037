import React from 'react';
import PropTypes from 'prop-types';

import styles from './Markers.module.css';

const Markers = props => {
  const { markers, size } = props;

  return (
    <div className={styles.markerContainer}>
      {markers.map((item, index) => {
        return (
          <div
            className={styles.marker}
            key={index}
            style={{ width: size, height: size, left: item.x - size / 2, top: item.y - size / 2 }}
          ></div>
        );
      })}
    </div>
  );
};

Markers.propTypes = {
  /**
   * markers list item, array of items
   */
  markers: PropTypes.array,
  /**
   * size of the marker
   */
  size: PropTypes.number
};

Markers.defaultProps = {
  markers: [],
  size: 8
};

export default Markers;
