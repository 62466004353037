import { get } from 'lodash';

import * as AT from '../../actionTypes';

const INIT_STATE = {
  appPreset: null,
  presetId: null,
  pluginsPresetId: 'default',
  size: { width: window.innerWidth, height: window.innerHeight },
  isPresetLoaded: false
};

const match = ({ state, action }) => {
  const { type, payload } = action;

  switch (type) {
    case AT.SET_APP_PRESET:
      return {
        ...state,
        appPreset: payload.appPreset
      };
    case AT.SET_PLUGIN_VIEWS:
      return {
        ...state,
        pluginsViews: { ...state.pluginsViews, [payload.id]: payload.views }
      };
    case AT.SET_PLUGINS_PRESET_ID:
      return {
        ...state,
        pluginsPresetId: payload.pluginsPresetId
      };

    case AT.SET_PLUGINS_ZONES:
      return {
        ...state,
        pluginsZones: payload.zones
      };

    case AT.SET_PRESET_ID:
      return {
        ...state,
        presetId: payload.presetId
      };
    case AT.PRESET_LOADED:
      return {
        ...state,
        isPresetLoaded: true
      };
    case AT.SET_WINDOW_SIZE:
      return {
        ...state,
        size: { ...payload }
      };
    default:
      return state;
  }
};

const featureName = AT.SHELL;
export default (state = INIT_STATE, action) => {
  const feature = get(action, 'meta.feature');
  return feature === featureName ? match({ state, action }) : state;
};
