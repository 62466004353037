import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../../Button';
import styles from './LowerJaw.module.css';

const propTypes = {
  /**
   * If `true`, jaw is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Callback, fired when clicking on jaw
   */
  onClick: PropTypes.func,
  /**
   * If `true`, jaw is selected
   */
  selected: PropTypes.bool
};

const defaultProps = {
  disabled: false,
  onClick: () => {},
  selected: false
};

function LowerJaw(props) {
  const { className: classNameProp, disabled, onClick, selected } = props;

  const className = classNames(classNameProp, {
    [styles.isDefault]: !(disabled || selected),
    [styles.isDisabled]: disabled,
    [styles.isSelected]: selected
  });

  return (
    <Button className={className} disabled={disabled} onClick={onClick} data-test-id="lower-jaw" id="lower-jaw">
      <div className={styles.after} />
    </Button>
  );
}

LowerJaw.propTypes = propTypes;
LowerJaw.defaultProps = defaultProps;

export default LowerJaw;
