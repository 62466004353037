import { AT } from '@web-3d-tool/redux-logic';
import {
  eventBus,
  logger,
  utils,
  PluginState,
  settingsManager,
  defaultPluginEvents,
  cacheManager,
  cacheKeys,
  PluginLoadingState
} from '@web-3d-tool/shared-logic';
import * as configuration from '@web-3d-tool/shared-logic/src/constants/configurationValues.constants';
import { get } from 'lodash';
import { initActionCreator } from './sdk.actions';

/**
 * This method provides an API for the plugins to interact with the application and listen to events.
 * @param {Object.<String>} pluginId - is the plugin id of the plugin using the sdk.
 * @param {Object.<Function>} getState - is the redux getState method.
 * @param {Object.<Function>} dispatch - is the redux dispatch method.
 */
const sdk = ({ pluginId, getState, dispatch }) => {
  const createAction = initActionCreator(pluginId);

  /**
   * This method returns the raw model as it unzipped from the .itr file
   */
  const getModel = () => {
    const model = cacheManager.get(cacheKeys.MODEL);
    const visibilityObject = cacheManager.get(cacheKeys.VISIBILITY_OBJECT);
    return { model, visibilityObject };
  };

  /**
   * This method returns the niri related data as it was unzipped from the .niri file
   */
  const getNiriData = () => {
    return cacheManager.get(cacheKeys.NIRI_DATA);
  };

  /**
   * This method loads a raw model using custom url (NOT taken from url params)
   */
  const loadModelFromBinary = ({ itrFileData, niriFileData }) => {
    const arr = [];

    arr.push(createAction({ type: AT.LOAD_MODEL, payload: { itrFileData, niriFileData, includesNiriData: true } }));

    arr.push({
      type: AT.LOAD_PRESET,
      payload: { forceReloadPreset: true, initiatingPlugin: pluginId, pluginsPresetId: 'default' },
      meta: {
        feature: AT.SHELL,
        plugin: pluginId
      }
    });

    dispatch(arr);
  };

  /**
   * This method returns the current stage from the renderer stage object. (state.renderer.stage)
   * @returns {Array} stage -is an array that contains one or more arrays, every object inside the arrays represents a renderer on the screen.
   * e.g. stage array that looks like this: [[{..}{..}],[{..}{..}{..}]] would represents a split screen with 2 renderers on top and 3 on the bottom of the screen.
   */
  const getStage = () => {
    const {
      renderer: { stage }
    } = getState();
    return stage;
  };

  /**
   * This method is creating and dispatching an action to change the current rendered model material.
   * @param {Object.<Object>} material - Three.js material object
   */
  const changeBaseMaterial = ({ material }) => {
    const type = AT.CHANGE_BASE_MATERIAL;
    const payload = { material };
    dispatch(createAction({ type, payload }));
  };

  /**
   * This method is creating and dispatching an action to render panorama on model.
   * @param {Object} data - the panorama data
   */
  const setPanorama = data => {
    const type = AT.SET_PANORAMA;
    const payload = { panoramaData: data };
    dispatch(createAction({ type, payload }));
  };

  /**
   * This method is used for subscribing to a specific system plugin event
   * @param {String} eventName - is the event name from the list of supported plugin events (defaultPluginEvents object)
   * @param {Function} func - is a callback function that would invoke as soon as the event is emmited.
   * @param {String} id - is an event id to subscribe for.
   * @returns {Object.<Function>} subscription - the method returns a disposable subscription .
   */
  const subscribeToPluginEvent = (eventName, func, id) => {
    const pluginID = id || pluginId;
    const event = `${pluginID}.${eventName}`;
    return eventBus.subscribeToEvent(event, func);
  };

  /**
   * This method is used for subscribing to a specific system event
   * @param {String} eventName - is the event name from the list of all system supported events
   * @param {Function} func - is a callback function that would invoke as soon as the event is emmited.
   * @returns {Object.<Function>} subscription - the method returns a disposable subscription .
   */
  const subscribeToEvent = (eventName, func) => {
    return eventBus.subscribeToEvent(eventName, func);
  };
  /**
   * This method is used for subscribing to a specific system plugin event
   * @param {String} eventName - is the event name from the list of supported plugin events (defaultPlugoinEvents object)
   * @param {Function} payload - is a data that will be passed to all subscribed handlers.
   */
  const raisePluginEvent = (eventName, payload) => {
    return eventBus.raiseEvent(`${pluginId}.${eventName}`, payload);
  };

  /**
   * This method is used for subscribing to a specific system plugin event
   * @param {String} eventName - is the event name from the list of supported plugin events (defaultPluginEvents object)
   * @param {Function} payload - is a data that will be passed to all subscribed handlers.
   */
  const raiseEvent = (eventName, payload) => {
    return eventBus.raiseEvent(eventName, payload);
  };

  /**
   * This method is responsible for hiding the plugin, setting the visible propery to false, and not showing it on the screen.
   */
  const hidePlugin = id => {
    const pluginID = id || pluginId;
    if (!isVisible(pluginID)) return;
    const type = AT.SET_PLUGIN_VISIBLE_STATE;
    const payload = { id: pluginID, state: false };
    dispatch(createAction({ type, payload }));
  };

  /**
   * This method is responsible for showing the plugin, setting the visible propery to true, and displaying it on the screen.
   */
  const showPlugin = id => {
    const pluginID = id || pluginId;
    if (isVisible(pluginID)) return;

    const type = AT.SET_PLUGIN_VISIBLE_STATE;
    const payload = { id: pluginID, state: true };
    dispatch(createAction({ type, payload }));
  };

  const addPluginViews = view => {
    const type = AT.ADD_PLUGIN_VIEW;
    const payload = { id: pluginId, view };
    dispatch(createAction({ type, payload }));
  };

  const removePluginViews = () => {
    const type = AT.REMOVE_PLUGIN_VIEW;
    const payload = { id: pluginId };
    dispatch(createAction({ type, payload }));
  };

  /**
   * This method notifies the plugin when it's state has changed.
   * @param {Object} state - is the next plugin state.
   * @param {Object} id - plugin id to change state.
   */
  const setPluginStateAndNotify = (state, id, statusMessage) => {
    const previousState = getPluginState(id);
    if (state === previousState) return;

    const arr = [
      createAction({ type: AT.SET_PLUGIN_STATE, payload: { id, state } }),
      createAction({ type: AT.SET_PLUGIN_STATUS_MESSAGE, payload: { id, statusMessage } })
    ];
    dispatch(arr);
    eventBus.raiseEvent(`${id}.${defaultPluginEvents.STATE_CHANGED}`, state);

    if (previousState === PluginState.PreInit || state === PluginState.NotAvailable) {
      eventBus.raiseEvent(`${id}.${defaultPluginEvents.PLUGIN_AVAILABILITY_CHANGED}`, id);
    }
  };

  /**
   * This method is responsible to set a new plugin state by id.
   * @param {Object} state - is the next plugin state.
   * @param {Object} otherPluginId - plugin id to change state.
   */
  const setStateByIdAndNotify = (state, otherPluginId) => {
    if (!otherPluginId) {
      logger
        .to('host')
        .error(`Other plugin id has not been provided`)
        .data({ module: 'sdk' })
        .end();
      return;
    }

    const oldState = getPluginState(otherPluginId);
    switch (state) {
      case PluginState.Active:
        oldState === PluginState.Inactive && setPluginStateAndNotify(state, otherPluginId);
        break;
      case PluginState.Inactive:
        oldState === PluginState.Active && setPluginStateAndNotify(state, otherPluginId);
        break;
      default:
        //Limit the abilities of a plugin when it tries to change the state of another plugin.
        logger
          .to('host')
          .error(`${pluginId} plugin is not permitted to change ${otherPluginId} plugin to ${state} state`)
          .data({ module: 'sdk' })
          .end();
    }
  };

  /**
   * This method is responsible to set the new plugin state (the plugin who calls it).
   * @param {Object} state - is the next plugin state.
   */
  const setStateAndNotify = (state, statusMessage) => {
    setPluginStateAndNotify(state, pluginId, statusMessage);
  };

  /**
   * This method is responsible to set the new plugin loading state (the plugin who calls it)
   * and to notify that the loading state has changed
   * @param {Object} state - is the next plugin loading state.
   */
  const setLoadingStateAndNotify = state => {
    const oldState = getPluginLoadingState(pluginId);
    if (state === oldState || oldState === PluginLoadingState.NotApplicable) return;

    const payload = { id: pluginId, state };
    dispatch(createAction({ type: AT.SET_PLUGIN_LOADING_STATE, payload }));
    eventBus.raiseEvent(`${pluginId}.${defaultPluginEvents.LOADING_STATE_CHANGED}`, state);
  };

  /**
   * This method is responsible to set the new plugin parameters state (the plugin who calls it).
   * @param {Object} parameters - plugin's params to change.
   * @param {string} plugin - pluginId.
   */
  const setParameters = (parameters, plugin = pluginId) => {
    const type = AT.SET_PLUGIN_PARAMETERS;
    const payload = { id: plugin, parameters };
    dispatch(createAction({ type, payload }));

    eventBus.raiseEvent(`${pluginId}.${defaultPluginEvents.PARAMETERS_CHANGED}`, parameters);
  };

  /**
    optionsStates.forEach((option) => {
          statusMessage: option.statusMessage,
      const allOptionsInState = (state) => options.every((option) => option.state === state);
      const isAnyOptionInState = (state) => options.some((option) => option.state === state);
    optionsVisibility.forEach((option) => {
    const syncOptionsVisibility = (options) => {
      const allInSameVisibility = () => options.every((option) => option.isVisible === firstOptionVisibility);
   * This method is responsible to reset the plugin parameters state (the plugin who calls it).
   * @param {Object} state - is the next plugin state.
   */
  const resetParameters = () => {
    const type = AT.RESET_PLUGIN_PARAMETERS;
    const payload = { id: pluginId };
    dispatch(createAction({ type, payload }));
  };

  /**
   * Sets a new preset.dff
   * @param {Object=} pluginsPresetId - payload information that can be passed with the activation event.
   */
  const setPreset = pluginsPresetId => {
    const type = AT.LOAD_PRESET;
    const payload = { forceReloadPreset: true, pluginsPresetId, initiatingPlugin: pluginId };
    dispatch({
      type,
      payload,
      meta: {
        feature: AT.SHELL
      }
    });
  };

  /**
   * @returns {Object} pluginState - the method returns the requester plugin state.
   */
  const getPluginState = id => {
    const pluginID = id || pluginId;
    const { plugins } = getState();
    return get(plugins, `${pluginID}.pluginState`);
  };

  /**
   * @returns {Object} pluginLoadingState - the method returns the requester plugin loading state.
   */
  const getPluginLoadingState = id => {
    const pluginID = id || pluginId;
    const { plugins } = getState();
    return get(plugins, `${pluginID}.pluginLoadingState`);
  };

  /**
   * @returns {Object} pluginPreviousState - the method returns the requesters' plugin previous state.
   */
  const getPluginPreviousState = id => {
    const pluginID = id || pluginId;
    const { plugins } = getState();
    return get(plugins, `${pluginID}.pluginPreviousState`);
  };

  /**
   * @returns {Object} pluginParameters - the method returns the requester plugin parameters.
   */
  const getPluginParameters = id => {
    const pluginID = id || pluginId;
    const { plugins } = getState();
    return get(plugins, `${pluginID}.pluginParameters`);
  };

  /**
   * @returns {Object} pluginsList - the method returns the array of activated plugins.
   */
  const getActivatedPlugins = () => {
    const { plugins } = getState();
    return Object.keys(plugins).filter(key => plugins[key].pluginState === PluginState.Active);
  };

  /**
   * This method is responsible to change visibility of each object it gets in the modelVisibilityObject by its object key,
   * the action it dispatches will result in a change of the metadata object.
   * @param {Object} modelVisibilityObject - is an object that represents the next visibility state of each object.
   */
  const changeModelObjectsVisibility = modelVisibilityObject => {
    const type = AT.CHANGE_MODEL_OBJECTS_VISIBILITY;
    const payload = modelVisibilityObject;
    dispatch(createAction({ type, payload }));
  };

  /**
   * This method returns the visibility object as a promise
   * the visibility object is an object which contains the visibility status of all the different
   * model parts
   */
  const getModelObjectsVisibility = () => {
    return new Promise((resolve, reject) => {
      const cb = object => (object ? resolve(object) : reject());
      const type = AT.GET_MODEL_OBJECTS_VISIBILITY;
      const payload = { cb };
      dispatch(createAction({ type, payload }));
    });
  };

  /**
   * This method will dispatch an action that will change the multibite active state.
   * @param {Object.<boolean>} multiBiteActive - This parameter indicates if the multibite is active or not.
   */
  const changeModelObjectsMultibite = ({ multiBiteActive, isMultiBite }) => {
    const type = AT.CHANGE_MODEL_OBJECTS_MULTIBITE;
    const payload = { multiBiteActive, isMultiBite };
    dispatch(createAction({ type, payload }));
  };

  /**
   * This method will dispatch an action that will change the metadata object and add the new texture to it.
   * @param {Object.<Object>} texture - is a Three.js Texture object
   * @param {Object.<String>} name  - is the name that the new texture will get.
   * @param {Object.<Object>} material  - is the material object for that texture.
   */
  const addTexture = ({ texture, name, material }) => {
    const type = AT.ADD_TEXTURE;
    const payload = { texture, name, material, pluginId };
    dispatch(createAction({ type, payload }));
  };

  /**
   * This method will dispatch an action that will change the metadata object and remove the requested texture from it.
   * @param {Object.<String>} name - The name of the texture.
   */
  const removeTexture = ({ name }) => {
    const type = AT.REMOVE_TEXTURE;
    const payload = { pluginId, name };
    dispatch(createAction({ type, payload }));
  };

  /**
   * This method reset stage with default camera position coords.
   * @param {Object.<Array>} stage - is object with data contains current jaws state options
   * @param {Function} callback - is a callback function that would invoke as soon as the event is emitted.
   */
  const resetRenderingStage = stage => {
    const type = AT.RESET_STAGE;
    dispatchAction({ type, payload: { stage } });
  };

  /**
   * This method turn on jaws toggle mode.
   * @param {Object.<Object>} cameraPosition - is object with data contains current jaws state options
   */
  const activateJawsToggleMode = ({ cameraPosition }) => {
    const type = AT.ACTIVATE_TOGGLE_MODE;
    dispatchAction({ type, payload: { stage: cameraPosition } });
  };

  /**
   * This method turn off jaws toggle mode.
   */
  const deactivateJawsToggleMode = () => {
    const type = AT.DEACTIVATE_TOGGLE_MODE;
    dispatchAction({ type });
  };

  /**
   * This method is responsible to split the screen according to the stage object it receives.
   * @param {Array} stage - is an array that contains one or more arrays, every object inside the arrays represents a renderer on the screen.
   * e.g. stage array that looks like this: [[{..}{..}],[{..}{..}{..}]] would represents a split screen with 2 renderers on top and 3 at the bottom of the screen.
   */
  const splitRenderingStage = stage => {
    const type = AT.SPLIT_RENDERING_STAGE;
    const payload = { stage };
    dispatch(createAction({ type, payload }));
  };

  const dispatchAction = ({ type, payload }) => {
    dispatch(createAction({ type, payload }));
  };

  /**
   * This method is creating and dispatching an action that will activate a system tool
   * @param {Object.<String>} name - is the name of the tool that is being activated.
   * @param {Object.<Object>} config - an object containing the configuration for the activated tool.
   */
  const activateTool = ({ name, config }) => {
    const type = AT.ACTIVATE_TOOL;
    const payload = { name, config };
    dispatch(createAction({ type, payload }));
  };

  /**
   * This method is creating and dispatching an action that will deactivated a system tool
   * @param {Object.<String>} name - the name of the tool that is being deactivated.
   */
  const deactivateTool = ({ name }) => {
    const type = AT.DEACTIVATE_TOOL;
    const payload = { name };
    dispatch(createAction({ type, payload }));
  };

  const changeToolConfig = ({ name, config }) => {
    const type = AT.CHANGE_TOOL_CONFIG;
    const payload = { name, config };
    dispatch(createAction({ type, payload }));
  };

  /**
   * This method returns the model Three.JS imperative objects as a promise.
   */
  const getThreeJSObjects = () => {
    return cacheManager.get(cacheKeys.THREE_OBJECTS);
  };

  /**
   * @param {Object.<String>=} url - optional; the requested url to extract parameter value from, default is the current window.location.href .
   * @param {Object.<String>} param - The requested parameter name.
   * @returns {String} - This method returns a url parameter (queryString)
   */
  const getConfigValue = value => {
    return settingsManager.getConfigValue(value);
  };

  /**
   *
   * @returns {Boolean} The method returns a boolean that states if the plugin id is visible.
   */
  const isVisible = id => {
    return get(getState(), `plugins.${id}.isVisible`);
  };

  /**
   * @returns {String} The method returns a string with the current environment value `scanner` or  `eup`
   */
  const getEnv = () => {
    return utils.getEnv();
  };

  /**
   * @returns {String} The method returns the case type 'ortho' / 'resto'
   */
  const getCaseType = () => {
    return settingsManager.getConfigValue(configuration.mode);
  };

  const getOrderId = () => {
    return settingsManager.getConfigValue(configuration.orderId);
  };

  /**
   * This method returns the size of the window (from the state)
   */
  const getWindowSize = () => {
    return get(getState(), 'shell.size');
  };

  /**
   * This method get jaws parameters object and change it.
   * it being used for manualy change the jaws and the model apperance
   * e.g { upper: { disable: false, checked: false } }
   * @param {*} parameters
   */
  const changeJawsState = parameters => {
    const id = 'jaws';
    eventBus.raiseEvent(`${id}.${defaultPluginEvents.PARAMETERS_CHANGED}`, parameters);
  };

  /**
   * This function return jaw parameters object from the store.
   */
  const getJawsState = () => {
    const jawsParameters = get(getState(), 'plugins.jaws.pluginParameters');
    return { ...jawsParameters };
  };

  const getAllEnabledPlugins = () => {
    const plugins = get(getState(), 'plugins');
    const enabledPlugins = [];
    Object.keys(plugins).forEach(function(key) {
      (plugins[key].pluginState === PluginState.Inactive ||
        plugins[key].pluginState === PluginState.Active ||
        plugins[key].pluginState === PluginState.Inaccessible) &&
        enabledPlugins.push(key);
    });
    return enabledPlugins;
  };

  const getAllPlugins = () => {
    const plugins = get(getState(), 'plugins');

    return Object.keys(plugins);
  };

  const changePluginZone = (pluginId, destinationZoneId) => {
    const type = AT.CHANGE_PLUGIN_ZONES;
    const payload = { pluginId, destinationZoneId };
    dispatch({
      type,
      payload,
      meta: {
        feature: AT.PLUGINS
      }
    });
  };

  return {
    getAllPlugins,
    isVisible,
    addPluginViews,
    removePluginViews,
    setPreset,
    getModel,
    getNiriData,
    loadModelFromBinary,
    getStage,
    subscribeToPluginEvent,
    subscribeToEvent,
    raisePluginEvent,
    raiseEvent,
    setStateAndNotify,
    setLoadingStateAndNotify,
    setStateByIdAndNotify,
    changeBaseMaterial,
    getState: getPluginState,
    getLoadingState: getPluginLoadingState,
    getPreviousState: getPluginPreviousState,
    getPluginParameters,
    getActivatedPlugins,
    getModelObjectsVisibility,
    changeModelObjectsVisibility,
    changeModelObjectsMultibite,
    addTexture,
    removeTexture,
    activateJawsToggleMode,
    deactivateJawsToggleMode,
    resetRenderingStage,
    splitRenderingStage,
    activateTool,
    deactivateTool,
    changeToolConfig,
    getThreeJSObjects,
    getEnv,
    getWindowSize,
    getJawsState,
    changeJawsState,
    getCaseType,
    hidePlugin,
    showPlugin,
    getOrderId,
    dispatchAction,
    getAllEnabledPlugins,
    setParameters,
    resetParameters,
    changePluginZone,
    setPanorama,
    getConfigValue
  };
};
/**
 * This method is used to set up the sdk for a specific plugin.
 * @param {Object.<String>} pluginId - is the plugin id that we want to set up the sdk for.
 * @param {Object.<Function>} getState - is a redux getState method.
 * @param {Object.<Function>} dispatch - is a redux dispatch method.
 */
const setupSDK = ({ pluginId, getState, dispatch, AT }) => {
  return sdk({ pluginId, getState, dispatch, AT });
};

export default setupSDK;
