import { Vector3, Matrix4 } from 'three';

class CameraParams {
  constructor(camera) {
    const viewingDirection = new Vector3();
    camera.getWorldDirection(viewingDirection);
    viewingDirection.normalize();

    this.unprojectMatrix = new Matrix4().multiplyMatrices(camera.matrixWorld, camera.projectionMatrixInverse);
    this.zoom = camera.zoom;
    this.up = camera.up;
    this.position = camera.position.clone();
    this.viewingDirection = viewingDirection;
  }
}

export { CameraParams };
