import { cloneDeep, get, isEqual, keys, set, values } from 'lodash';
import {
  cacheKeys,
  cacheManager,
  createChromaticMaterial,
  eventBus,
  extractItrModel,
  getCameraPosByCaseType,
  getJawByObjectKey,
  getModel,
  getPhotosFileByEnv,
  globalEventsKeys,
  hostCommunicationManager,
  isAnyPretreatmentJawExists,
  isNiriEnabled,
  isTextureMappingExistInGeometry,
  logger,
  OutgoingMessagesKeys,
  preparePhotosData,
  settingsManager
} from '@web-3d-tool/shared-logic';
import * as configuartion from '@web-3d-tool/shared-logic/src/constants/configurationValues.constants';

import createMiddleware from '../../../middlewareHelper';
import * as AT from '../../actionTypes';
import {
  loadingModelError,
  modelLoaded,
  setIsThreejsObjectsReady,
  setMetadata,
  setModelId,
  setModelIsLoading,
  setReadonlyStage,
  setRenderingReadonlyStage,
  setRenderingStage,
  setResetCameraRotationOnUpdate,
  setTextures,
  setPanorama
} from './renderer.actions';
import rendererLogic from './renderer.logic';

const defaultModelMaterial = createChromaticMaterial();

let imperativeThreeObjects = {};

//******************************
const feature = AT.RENDERER;
//******************************

/**
 * This function will be invoked for every action and not only for
 * renderer actions. ({meta:{feature:AT.RENDERER}})
 * The reason we use it is, because there are actions that being dispatch from
 * the plugins that should be handled here in the renderer
 * @param {action,dispatch,getState} param0
 */
export const goThroughOverride = async ({ action, dispatch, getState }) => {
  const { payload, type, meta } = action;

  switch (type) {
    case AT.CHANGE_BASE_MATERIAL:
      {
        const { material } = payload;
        const { metadata: currentMetadata } = getState().renderer;
        const { uuid, type } = material;

        if (!cacheManager.get(`${cacheKeys.MATERIALS}.${uuid}`)) {
          cacheManager.set(`${cacheKeys.MATERIALS}.${uuid}`, material);
        }

        const geometriesToChange = rendererLogic.getGeometriesWithMaterialAndColorArray(currentMetadata);

        const metadata = rendererLogic.setMaterialToMetadata({
          metadata: currentMetadata,
          material: { uuid, type },
          geometriesToChange
        });

        dispatch(setMetadata({ metadata }));
      }
      break;

    case AT.ADD_TEXTURE:
      {
        const {
          renderer: {
            metadata: { textures }
          }
        } = getState();

        const newTexturesArr = [...textures, payload];

        dispatch(setTextures({ textures: newTexturesArr }));
      }
      break;

    case AT.REMOVE_TEXTURE:
      {
        const {
          renderer: {
            metadata: { textures }
          }
        } = getState();

        const { pluginId, name } = payload;

        const newTexturesArr = textures.filter(({ pluginId: pId, name: n }) => pId !== pluginId && n !== name);

        dispatch(setTextures({ textures: newTexturesArr }));
      }
      break;

    case AT.GET_MODEL_OBJECTS_VISIBILITY:
      {
        const {
          renderer: { metadata }
        } = getState();

        const { cb } = payload;
        const visibilityObject = rendererLogic.getVisibilityObjectFromMetadata(metadata);
        cb(visibilityObject);
      }
      break;

    case AT.CHANGE_MODEL_OBJECTS_VISIBILITY:
      {
        const { metadata: currentMetadata } = getState().renderer;

        const currentVisibilityValues = rendererLogic.getVisibilityObjectFromMetadata(currentMetadata);
        const newVisibilityValues = payload;
        let metadata = rendererLogic.changeMetadataByVisibilityObject(
          currentVisibilityValues,
          newVisibilityValues,
          currentMetadata,
          payload.isToggleModeActive
        );

        let visibilityObject = rendererLogic.getVisibilityObjectFromMetadata(metadata);

        if (!isEqual(visibilityObject, currentVisibilityValues)) {
          const actions = [setMetadata({ metadata })];
          if (action.meta.plugin === 'jaws') {
            const {
              jaws: { pluginParameters: jawsState }
            } = getState().plugins;

            logger
              .log(`Navigating jaws: ${JSON.stringify(currentVisibilityValues)}`)
              .to('host')
              .data({ module: 'renderer.middleware' })
              .end();

            dispatch(actions);
            const visibilityObject = cacheManager.get(cacheKeys.VISIBILITY_OBJECT);
            eventBus.raiseEvent(globalEventsKeys.JAWS_CHANGED, { visibilityObject, jawsState });

            break;
          }
          dispatch(actions);
        }
      }
      break;

    case AT.CHANGE_MODEL_OBJECTS_MULTIBITE:
      {
        const { multiBiteActive } = payload;
        const { metadata: currentMetadata } = getState().renderer;
        const model = cacheManager.get(cacheKeys.MODEL);

        const {
          objects: { lower_jaw: geometry },
          multiBite: { transformationMatrix, transformationInverseMatrix }
        } = model;

        const matrix = multiBiteActive ? transformationMatrix : transformationInverseMatrix;
        geometry.applyMatrix(matrix);

        const metadata = { ...currentMetadata, bite: { active: multiBiteActive } };

        let actionsArray = [setMetadata({ metadata })];

        dispatch(actionsArray);
        eventBus.raiseEvent(globalEventsKeys.MULTIBITE_CHANGED, { multiBiteActive });
      }
      break;

    case AT.SPLIT_RENDERING_STAGE:
      {
        const { stage } = payload;
        dispatch([
          setRenderingStage({ stage: cloneDeep(stage) }),
          setRenderingReadonlyStage({ readonlyStage: cloneDeep(stage) })
        ]);
      }
      break;

    case AT.RESET_STAGE:
      {
        const { stage } = payload;
        dispatch([setRenderingStage({ stage }), setRenderingReadonlyStage({ readonlyStage: cloneDeep(stage) })]);
      }
      break;

    case AT.ACTIVATE_TOGGLE_MODE:
      {
        const { stage } = payload;
        const clonedStage = cloneDeep(stage);

        dispatch([
          setRenderingStage({ stage }),
          setRenderingReadonlyStage({ readonlyStage: clonedStage }),
          setResetCameraRotationOnUpdate(true)
        ]);
      }
      break;

    case AT.DEACTIVATE_TOGGLE_MODE:
      {
        dispatch([setResetCameraRotationOnUpdate(false)]);
      }
      break;

    case AT.CAMERA_STOPPED_MOVING:
      {
        const {
          camera: { position, up, zoom },
          arrayIndex,
          inArrayIndex
        } = payload;
        const updatedCameraProps = {
          position: values(position),
          up: values(up),
          zoom
        };
        const {
          renderer: { readonlyStage }
        } = getState();

        set(readonlyStage, [arrayIndex, inArrayIndex], updatedCameraProps);
        dispatch(setReadonlyStage({ readonlyStage }));
        eventBus.raiseEvent(globalEventsKeys.CAMERA_STOPPED_MOVING, { ...payload });
      }
      break;

    case AT.SET_METADATA:
      {
        let visibilityObject = rendererLogic.getVisibilityObjectFromMetadata(payload.metadata);
        visibilityObject = Object.keys(
          Object.fromEntries(Object.entries(visibilityObject).filter(([objectKey, isVisible]) => isVisible))
        );
        cacheManager.set(cacheKeys.VISIBILITY_OBJECT, visibilityObject);
      }
      break;

    case AT.LOAD_MODEL:
      {
        dispatch(setModelIsLoading(true));
        const arr = [];
        let model = null;

        try {
          cacheManager.set(cacheKeys.MODEL, model);

          // payload will contain itr data only in debug mode
          if (!payload?.itrFileData) {
            model = await getModel();
          } else {
            model = await extractItrModel(payload.itrFileData);
          }

          cacheManager.set(cacheKeys.MODEL, model);

          const { id } = model;
          arr.push(...[setModelId({ id }), modelLoaded({ ...payload, model }), setModelIsLoading(false)]);
          hostCommunicationManager.sendMessageToHost(OutgoingMessagesKeys.MODEL_LOADED);
        } catch (err) {
          if (err.name === 'max_attempts_exceeded') {
            hostCommunicationManager.sendMessageToHost(OutgoingMessagesKeys.OPEN_EXCEEDED_DOWNLOAD_ATTEMPTS_POPUP);
          }
          arr.push(loadingModelError({ err }));
        }

        dispatch(arr);
      }
      break;

    case AT.SET_PANORAMA:
      {
        if (meta.plugin === 'panorama') {
          dispatch(setPanorama(payload));
        }
      }
      break;
    default:
    // no default
  }
};

export const middleware = async ({ action, dispatch, getState }) => {
  const { payload, type } = action;

  switch (type) {
    case AT.MODEL_LOADED:
      {
        const {
          renderer: { metadata: currentMetadata }
        } = getState();
        const caseType = settingsManager.getConfigValue(configuartion.mode);
        const model = payload.model;
        const isJawToggleExist = isAnyPretreatmentJawExists(model.objects);
        const metadata = keys(model.objects).reduce((acc, key) => {
          const jawKey = getJawByObjectKey(key);

          const isVisible = !key.includes('pretreatment');
          const hasColor = !!get(model, `objects.${key}.attributes.color`);
          const hasTextures = isTextureMappingExistInGeometry(model.objects[key]);

          const material = model.objects[key].material || defaultModelMaterial;

          if (material) {
            cacheManager.set(`${cacheKeys.MATERIALS}.${material.uuid}`, material);
          }

          set(acc, `${jawKey}.${key}`, { visible: isVisible, material, hasColor, hasTextures });
          set(acc, `${jawKey}.visible`, true);

          return acc;
        }, currentMetadata);

        metadata.isJawToggleExist = isJawToggleExist;

        cacheManager.set(cacheKeys.MODEL, model);

        logger
          .info(`itr file version ${model.itrFileVersion}`)
          .data({ module: 'renderer.middleware' })
          .end();

        const { id } = model;
        const stage = [[{ cameraProps: getCameraPosByCaseType(caseType, 'front') }]];
        const clonedStage = cloneDeep(stage);
        dispatch([
          setMetadata({ metadata }),
          setModelId({ id }),
          setRenderingStage({ stage }),
          setRenderingReadonlyStage({ readonlyStage: clonedStage })
        ]);
        const visibilityObject = cacheManager.get(cacheKeys.VISIBILITY_OBJECT);
        eventBus.raiseEvent(globalEventsKeys.MODEL_LOADED, { ...payload, visibilityObject });

        const loadNiriData = async () => {
          let niriData = null;

          try {
            cacheManager.set(cacheKeys.NIRI_DATA, niriData);

            if (!isNiriEnabled()) return;
            // payload will contain niri data only in debug mode
            if (!payload.includesNiriData) {
              const zippedPhotosFile = await getPhotosFileByEnv();
              niriData = await preparePhotosData(zippedPhotosFile);
            } else {
              payload.niriFileData && (niriData = await preparePhotosData(payload.niriFileData));
            }
            cacheManager.set(cacheKeys.NIRI_DATA, niriData);
          } catch (err) {
            logger
              .error(err.message)
              .data({ module: 'renderer.middleware' })
              .end();
            niriData = null;
          }

          eventBus.raiseEvent(globalEventsKeys.NIRI_LOADED, niriData);
        };
        await loadNiriData();
      }
      break;

    case AT.IMPERATIVE_THREE_OBJECTS_READY:
      {
        const { objects } = payload;
        imperativeThreeObjects = { ...objects };
        cacheManager.set(cacheKeys.THREE_OBJECTS, imperativeThreeObjects);
        const { zoom } = imperativeThreeObjects.camera;
        const { readonlyStage } = getState().renderer;

        //#################################################################################/
        // This is done for automation team so that they will have "initial zoom" to test
        if (get(readonlyStage, '[0][0].cameraProps')) {
          readonlyStage[0][0].cameraProps.zoom = zoom;
        }
        //#################################################################################/

        dispatch([setIsThreejsObjectsReady(true), setRenderingReadonlyStage({ readonlyStage: [...readonlyStage] })]);
      }
      break;

    default:
    // no default
  }
};

export default createMiddleware({ feature, goThroughOverride })(middleware);
