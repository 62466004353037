import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../../Button';
import styles from './Bite.module.css';

const propTypes = {
  /**
   * If `true`, bite is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Callback, fired when clicking on bite
   */
  onClick: PropTypes.func,
  /**
   * Is MultiBite active
   */
  multiBiteActive: PropTypes.bool,
  /**
   * Is MultiBite enabled
   */
  isMultiBite: PropTypes.bool
};

const defaultProps = {
  disabled: false,
  multiBiteActive: false,
  isMultiBite: false
};

function Bite(props) {
  const { className: classNameProp, disabled, onClick, isMultiBite, multiBiteActive } = props;
  const className = classNames(classNameProp, {
    [styles.isDefault]: !disabled && !isMultiBite,
    [styles.isDisabled]: disabled,
    [styles.isOneSelected]: isMultiBite && !multiBiteActive,
    [styles.isTwoSelected]: isMultiBite && multiBiteActive
  });

  return (
    <Button className={className} disabled={disabled} onClick={onClick} data-test-id="bite" id="bite">
      <div className={styles.after} />
    </Button>
  );
}

Bite.propTypes = propTypes;
Bite.defaultProps = defaultProps;

export default Bite;
