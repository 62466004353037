import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { toolsEvents } from '@web-3d-tool/shared-logic/src/event-bus/supportedKeys';
import { DraggableObject } from '@web-3d-tool/shared-ui';
import { get } from 'lodash';
import classNames from 'classnames';
import { eventBus } from '@web-3d-tool/shared-logic/src';
import styles from './ToolsContainer.module.css';

const ToolsContainer = props => {
  const { draggableObjectActive, draggableObjectConfig } = props;

  const className = classNames(styles.absoluteObject, get(draggableObjectConfig, 'className', ''));

  return (
    <Fragment>
      {draggableObjectActive && (
        <DraggableObject
          {...draggableObjectConfig}
          className={className}
          onStart={data => eventBus.raiseEvent(toolsEvents.DRAGGABLE_OBJECT.ON_START, data)}
          onDrag={data => eventBus.raiseEvent(toolsEvents.DRAGGABLE_OBJECT.ON_DRAG, data)}
          onStop={data => eventBus.raiseEvent(toolsEvents.DRAGGABLE_OBJECT.ON_STOP, data)}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ tools }) => {
  const {
    draggableObject: { active: draggableObjectActive, config: draggableObjectConfig }
  } = tools;
  return { draggableObjectActive, draggableObjectConfig };
};

export default connect(mapStateToProps)(ToolsContainer);
