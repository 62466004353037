/**
 * this component was originally taken from:
 * https://github.com/mrdoob/three.js/blob/r108/examples/jsm/lines
 */

import { InstancedInterleavedBuffer, InterleavedBufferAttribute, Mesh, Vector3 } from 'three';
import { LineSegmentsGeometry } from './LineSegmentsGeometry.js';
import { LineMaterial } from './LineMaterial.js';

const LineSegmentsExtended = function(geometry, material) {
  Mesh.call(this);

  this.type = 'LineSegmentsExtended';

  this.geometry = geometry !== undefined ? geometry : new LineSegmentsGeometry();
  this.material = material !== undefined ? material : new LineMaterial({ color: Math.random() * 0xffffff });
};

LineSegmentsExtended.prototype = Object.assign(Object.create(Mesh.prototype), {
  constructor: LineSegmentsExtended,

  isLineSegmentsExtended: true,

  computeLineDistances: (function() {
    // for backwards-compatability, but could be a method of LineSegmentsGeometry...

    const start = new Vector3();
    const end = new Vector3();

    return function computeLineDistances() {
      const geometry = this.geometry;

      const instanceStart = geometry.attributes.instanceStart;
      const instanceEnd = geometry.attributes.instanceEnd;
      const lineDistances = new Float32Array(2 * instanceStart.data.count);

      for (let i = 0, j = 0, l = instanceStart.data.count; i < l; i++, j += 2) {
        start.fromBufferAttribute(instanceStart, i);
        end.fromBufferAttribute(instanceEnd, i);

        lineDistances[j] = j === 0 ? 0 : lineDistances[j - 1];
        lineDistances[j + 1] = lineDistances[j] + start.distanceTo(end);
      }

      const instanceDistanceBuffer = new InstancedInterleavedBuffer(lineDistances, 2, 1); // d0, d1

      geometry.addAttribute('instanceDistanceStart', new InterleavedBufferAttribute(instanceDistanceBuffer, 1, 0)); // d0
      geometry.addAttribute('instanceDistanceEnd', new InterleavedBufferAttribute(instanceDistanceBuffer, 1, 1)); // d1

      return this;
    };
  })(),

  copy: function() {
    return this;
  }
});

export { LineSegmentsExtended };
