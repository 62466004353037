import React, { useEffect, useState } from 'react';

const ProgressBar = ({ withPercents }) => {
  const [completed, setCompleted] = useState(Math.floor(Math.random() * 100) + 1);

  const containerStyles = {
    position: 'relative',
    height: 12,
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 50
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: 'rgba(0, 154, 206, 1)',
    borderRadius: 'inherit',
    textAlign: 'center',
    transition: 'width 0.5s ease-out'
  };

  const labelContainerStyles = {
    width: '100%',
    left: 0,
    top: -4,
    position: 'absolute'
  };

  const labelStyles = {
    fontSize: '12px',
    color: 'white'
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (completed >= 100) setCompleted(0);
      else {
        setCompleted(prev => prev + 5);
      }
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, [completed, setCompleted]);

  return (
    <div style={containerStyles}>
      {withPercents && (
        <div style={labelContainerStyles}>
          <span style={labelStyles}>{`${completed}%`}</span>
        </div>
      )}
      <div style={fillerStyles}></div>
    </div>
  );
};

export default ProgressBar;
