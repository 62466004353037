import { Slice } from 'react-pie-menu';
import styled from 'styled-components';

export const StyledSlice = styled(Slice)`
  background: white;
  box-shadow: inset 15px 4px 90px rgba(51, 189, 250, 0.12);
  color: rgba(0, 103, 172, 0.8);
  &[_highlight][enabled='true'],
  &:active[enabled='true'] {
    color: white;
    cursor: pointer;
    background: #33bdfa;
    &[filled='true'] #explorer-icon > svg {
      & path {
        fill: #fff;
      }
      & g {
        fill: #fff;
      }
    }
  }

  &[enabled='false'] {
    cursor: unset;
    color: #b5d3e7;
    background: rgba(224, 237, 244, 0.4);
    & #explorer-icon > svg > path {
      stroke: #7f7f7f;
    }
  }

  &[loading_state='LoadingFail'] {
    color: rgba(217, 102, 87, 1);
    background: linear-gradient(0deg, #fff5f5, #fff5f5), #e3e8eb;
  }
`;
