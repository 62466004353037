import React from 'react';
import { shouldShowModelOCCTexture } from '@web-3d-tool/shared-logic';

export const MeshWithOCCTexture = props => {
  const { geometry, map, textureName, modelName } = props;

  if (!(textureName.includes('occ') && shouldShowModelOCCTexture(modelName))) {
    return null;
  }

  return (
    <mesh geometry={geometry}>
      <meshPhongMaterial name="OCCMaterial" attach="material" map={map} transparent />
    </mesh>
  );
};
