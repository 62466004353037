import * as AT from '../../actionTypes';

export const appLoaded = () => {
  return {
    type: AT.APP_LOADED,
    payload: {},
    meta: {
      feature: AT.SHELL
    }
  };
};
export const presetLoaded = () => {
  return {
    type: AT.PRESET_LOADED,
    payload: {},
    meta: {
      feature: AT.SHELL
    }
  };
};

export const loadPreset = ({ forceReloadPreset = false, pluginsPresetId }) => {
  return {
    type: AT.LOAD_PRESET,
    payload: { forceReloadPreset, pluginsPresetId },
    meta: {
      feature: AT.SHELL
    }
  };
};

export const setAppPreset = ({ appPreset }) => {
  return {
    type: AT.SET_APP_PRESET,
    payload: { appPreset },
    meta: {
      feature: AT.SHELL
    }
  };
};

export const setPluginViews = ({ id, views }) => {
  return {
    type: AT.SET_PLUGIN_VIEWS,
    payload: { id, views },
    meta: {
      feature: AT.SHELL
    }
  };
};

export const setPluginsPresetId = ({ pluginsPresetId }) => {
  return {
    type: AT.SET_PLUGINS_PRESET_ID,
    payload: { pluginsPresetId },
    meta: {
      feature: AT.SHELL
    }
  };
};

export const setPresetId = ({ presetId }) => {
  return {
    type: AT.SET_PRESET_ID,
    payload: { presetId },
    meta: {
      feature: AT.SHELL
    }
  };
};

export const setPluginsZones = ({ zones }) => {
  return {
    type: AT.SET_PLUGINS_ZONES,
    payload: { zones },
    meta: {
      feature: AT.SHELL
    }
  };
};

export const setWindowSize = ({ width, height }) => {
  return {
    type: AT.SET_WINDOW_SIZE,
    payload: { width, height },
    meta: {
      feature: AT.SHELL
    }
  };
};
