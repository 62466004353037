import { get } from 'lodash';

import * as pluginsMetaData from '@web-3d-tool/shared-logic/src/constants/tools.constants';

import { PluginState, PluginLoadingState } from '@web-3d-tool/shared-logic';

import * as AT from '../../actionTypes';

const INIT_STATE = {};
for (const plugin of Object.values(pluginsMetaData)) {
  INIT_STATE[plugin.id] = {
    pluginState: PluginState.PreInit,
    pluginLoadingState: plugin.loadable ? PluginLoadingState.PreLoading : PluginLoadingState.NotApplicable,
    pluginPreviousState: PluginState.PreInit,
    isVisible: true,
    statusMessage: ''
  };
}
const match = ({ state, action }) => {
  const { type, payload } = action;

  switch (type) {
    case AT.SET_PLUGIN_VISIBLE_STATE:
      return {
        ...state,
        [payload.id]: { ...state[payload.id], isVisible: payload.state }
      };

    case AT.SET_PLUGIN_STATE:
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          pluginState: payload.state,
          pluginPreviousState: state[payload.id].pluginState
        }
      };

    case AT.SET_PLUGIN_LOADING_STATE:
      return {
        ...state,
        [payload.id]: { ...state[payload.id], pluginLoadingState: payload.state }
      };

    case AT.SET_PLUGIN_PARAMETERS:
      const currentPluginParameters = get(state, `${payload.id}.pluginParameters`) || {};
      return {
        ...state,
        [payload.id]: { ...state[payload.id], pluginParameters: { ...currentPluginParameters, ...payload.parameters } }
      };

    case AT.RESET_PLUGIN_PARAMETERS:
      return {
        ...state,
        [payload.id]: { ...state[payload.id], pluginParameters: {} }
      };

    case AT.SET_PLUGIN_STATUS_MESSAGE:
      return {
        ...state,
        [payload.id]: { ...state[payload.id], statusMessage: payload.statusMessage }
      };

    default:
      return state;
  }
};

const featureName = AT.PLUGINS;
export default (state = INIT_STATE, action) => {
  const feature = get(action, 'meta.feature');
  return feature === featureName ? match({ state, action }) : state;
};
