import * as AT from '../../actionTypes';

export const setToolActive = ({ name, state }) => {
  return {
    type: AT.SET_TOOL_ACTIVE,
    payload: { name, state },
    meta: {
      feature: AT.TOOLS
    }
  };
};

export const setToolConfig = ({ name, config }) => {
  return {
    type: AT.SET_TOOL_CONFIG,
    payload: { name, config },
    meta: {
      feature: AT.TOOLS
    }
  };
};
