export const lazyLoadPreset = async presetId => {
  try {
    const preset = await import(`./${presetId}.preset`);
    const { pluginsPreset, zonesPreset } = preset.default;
    const pluginsPresetResolved = await pluginsPreset();
    return { pluginsPreset: pluginsPresetResolved, zonesPreset };
  } catch (err) {
    return Promise.reject(err);
  }
};

export const composePreset = async ({ preset, pluginsPresetId }) => {
  const { zonesPreset, pluginsPreset } = preset;
  const currentPreset = pluginsPreset[pluginsPresetId];

  zonesPreset.zones = zonesPreset.zones.map(zone => {
    const plugins = currentPreset[zone.id];
    zone.plugins = plugins;
    return zone;
  });
  return zonesPreset;
};
