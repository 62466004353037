function copyTouch(touch) {
  return {
    identifier: touch.pointerId,
    pageX: touch.clientX,
    pageY: touch.clientY
  };
}

/**
 * TouchPointerAdapter
 *
 * Keeps track on the number of touch pointers on screen.
 */
class TouchPointerAdapter {
  touches = [];

  pointerdown(event) {
    this.touches.push(copyTouch(event));
  }

  pointermove(event) {
    const index = this.getTouchIndexById(event.pointerId);
    this.touches.splice(index, 1, copyTouch(event)); // swap in the new touch record
  }

  pointerup(event) {
    const index = this.getTouchIndexById(event.pointerId);
    this.touches.splice(index, 1); // remove it; we're done
  }

  getTouchIndexById(id) {
    const index = this.touches.findIndex(({ identifier }) => identifier === id);
    return index;
  }
}

export default TouchPointerAdapter;
