// General params
export const companyId = 'companyId';
export const userId = 'userId';
export const orderId = 'orderId';
export const locale = 'locale';
export const mode = 'mode';
export const model = 'model';
export const layout360 = 'layout360';

//MIDC host only params - null in scanner host
export const timberUrl = 'timberUrl';
export const fromGenericApi = 'fromGenericApi';
export const serverEndpoint = 'serverEndpoint';

// PLUGIN PARAMS
//5D
export const isEVxEnabled = 'isEVxEnabled';
export const niri = 'niri';
export const isIOCEnabled = 'isIOCEnabled';
export const PanoramaEnabled = 'PanoramaEnabled';
// Texture mapping
export const isTextureMappingDisabled = 'isTextureMappingDisabled';
// Timelapse
export const TimeLapseEnabled = 'TimeLapseEnabled';
// IOSim Plus
export const isIOSimPlusEnabled = 'isIOSimPlusEnabled';
export const simulationStatus = 'simulationStatus';
export const startSimulationTime = 'startSimulationTime';
export const expectedDuration = 'expectedDuration';
// IOSim classic
export const isIOSimGoOutcomeEnabled = 'isIosimGoOutcomeEnabled';
export const isIOSimOutcome = 'isIoismOutcomeEnabled';
export const isIOSimProgressAssessment = 'isIoismProgressAssessmentEnabled';
export const isIOSimGoAssessment = 'isIoismGoAssessmentEnabled';
// Debug
export const debug = 'debug';
