import { Vector4, Vector3, ShaderMaterial, DoubleSide } from 'three';

export class ColorMaterial extends ShaderMaterial {
  constructor(texture, ...args) {
    const { uniforms, vertexShader, fragmentShader } = colorShader;

    super({
      ...args,
      uniforms: { ...uniforms, map: { ...uniforms.map, value: texture } },
      vertexShader,
      fragmentShader,
      vertexColors: true,
      side: DoubleSide
    });
  }
}

const colorShader = {
  uniforms: {
    specularity: { type: 'f', value: 0.6 },
    shininess: { type: 'f', value: 3.2 },
    brightness: { type: 'f', value: 2.5 },
    diffuse: { type: 'f', value: 0.55 },

    light_ambient: {
      type: 'v4',
      value: new Vector4(0.58, 0.58, 0.58, 1.0)
    },

    light0_position: { type: 'v3', value: new Vector3(0, 30, -100) },
    light0_dir: { type: 'v3', value: new Vector3(0.0, -0.29, 0.96) },
    light0_diffuse: {
      type: 'v4',
      value: new Vector4(0.0, 0.0, 0.0, 1.0)
    },
    light0_specular: {
      type: 'v4',
      value: new Vector4(0.0, 0.0, 0.0, 1.0)
    },

    light1_position: { type: 'v3', value: new Vector3(-100, 10, -100) },
    light1_dir: { type: 'v3', value: new Vector3(0.71, -0.07, 0.71) },
    light1_diffuse: {
      type: 'v4',
      value: new Vector4(0.2, 0.2, 0.2, 1.0)
    },
    light1_specular: {
      type: 'v4',
      value: new Vector4(0.2, 0.2, 0.2, 1.0)
    },

    light2_position: { type: 'v3', value: new Vector3(100, 10, -100) },
    light2_dir: { type: 'v3', value: new Vector3(-0.71, -0.07, 0.71) },
    light2_diffuse: {
      type: 'v4',
      value: new Vector4(0.2, 0.2, 0.2, 1.0)
    },
    light2_specular: {
      type: 'v4',
      value: new Vector4(0.2, 0.2, 0.2, 1.0)
    },
    map: { type: 't', value: null }
  },
  vertexShader: /* glsl */ `
    varying vec4 myPos;
    varying vec4 myColor;
    varying vec3 myNormal;
    varying vec2 myUv;
    
    attribute vec2 occUv;

    void main(){ 
      myNormal = mat3(projectionMatrix) * mat3(modelViewMatrix) * normal;
      myColor = vec4(color, 1.0);
      myUv = occUv;

      vec4 pos = modelViewMatrix * vec4(position, 1.0);

      gl_Position = projectionMatrix * pos;
    }
`,
  fragmentShader: /* glsl */ `
    // ambient light
    uniform vec4 light_ambient;

    // light #0
    uniform vec3 light0_dir;
    uniform vec3 light0_position;
    uniform vec4 light0_diffuse;
    uniform vec4 light0_specular;
    const float light0_phi = 0.98; // 10 degrees
    const float light0_theta = 0.86; // 30 degrees

    // light #1
    uniform vec3 light1_dir;
    uniform vec3 light1_position;
    uniform vec4 light1_diffuse;
    uniform vec4 light1_specular;

    // light #2
    uniform vec3 light2_dir;
    uniform vec3 light2_position;
    uniform vec4 light2_diffuse;
    uniform vec4 light2_specular;

    // material reflection properties
    uniform float specularity;
    uniform float shininess;
    uniform float diffuse;

    // extra effects
    uniform float brightness;

    uniform sampler2D map;

    varying vec4 myPos;
    varying vec4 myColor;
    varying vec3 myNormal;
    varying vec2 myUv;

    const float SRGB_ALPHA = 0.055;

    float rgb_to_srgb(float channel) { 
      if(channel <= 0.0031308) 
        return 12.92 * channel;
      else 
        return (1.0 + SRGB_ALPHA) * pow(channel, 1.0/2.4) - SRGB_ALPHA;
    }

    float srgb_to_rgb(float channel) { 
      if (channel <= 0.04045)
          return channel / 12.92;
      else 
          return pow((channel + SRGB_ALPHA) / (1.0 + SRGB_ALPHA), 2.4);
    }

    vec4 rgb_to_srgb(vec4 rgb) { 
      return vec4( rgb_to_srgb(rgb.r), rgb_to_srgb(rgb.g), rgb_to_srgb(rgb.b), rgb.a );
    }

    vec4 srgb_to_rgb(vec4 srgb) { 
      return vec4( srgb_to_rgb(srgb.r), srgb_to_rgb(srgb.g), srgb_to_rgb(srgb.b), srgb.a );
    }

    void main(){ 
      vec3 N = normalize(myNormal);

      float light0_dot_N = clamp(dot(light0_dir, N), 0.0, 1.0);
      float light1_dot_N = clamp(dot(light1_dir, N), 0.0, 1.0);
      float light2_dot_N = clamp(dot(light2_dir, N), 0.0, 1.0);

      vec3 half_angle0 = normalize(normalize(light0_position - myPos.xyz) - light0_dir);
      vec3 half_angle1 = normalize(normalize(light1_position - myPos.xyz) - light1_dir);
      vec3 half_angle2 = normalize(normalize(light2_position - myPos.xyz) - light2_dir);

      float specular_lighting0_factor = smoothstep(light0_theta, light0_phi, dot(half_angle0, N));
      float specular_lighting1_factor = smoothstep(light0_theta, light0_phi, dot(half_angle1, N));
      float specular_lighting2_factor = smoothstep(light0_theta, light0_phi, dot(half_angle2, N));

      float specular_lighting0_intensity = pow(specular_lighting0_factor, shininess);
      float specular_lighting1_intensity = pow(specular_lighting1_factor, shininess);
      float specular_lighting2_intensity = pow(specular_lighting2_factor, shininess);

      vec4 d0 = light0_diffuse * light0_dot_N;
      vec4 d1 = light1_diffuse * light1_dot_N;
      vec4 d2 = light2_diffuse * light2_dot_N;
      vec4 s0 = light0_specular * specular_lighting0_intensity;
      vec4 s1 = light1_specular * specular_lighting1_intensity;
      vec4 s2 = light2_specular * specular_lighting2_intensity;

      vec4 frag_colour = srgb_to_rgb(myColor);

      #ifdef USE_MAP 
          vec4 texelColor = texture2D( map, myUv );
          frag_colour *= texelColor;
      #endif 

      frag_colour *= (light_ambient + diffuse * (d0 + d1 + d2) + specularity * (s0 + s1 + s2));
      frag_colour *= brightness;
      frag_colour = rgb_to_srgb(frag_colour);

      gl_FragColor = frag_colour;
    }
  `
};
