import logger from '../../logger';
import { EXTRACTED_MODEL } from '../../constants/model.constants';
import { extractFile, getZippedObject } from '../../unzip-util';
import { downloadFile } from '../download-file';

import { getModelByMetadata, getModelTextures, waitForItrToBeCreated } from './itr-fetcher.service.logic';

export const getITRModel = async itrModelUrl => {
  const response = await fetchModel(itrModelUrl);
  const arraybufferData = await response.arrayBuffer();
  return await extractItrModel(arraybufferData, itrModelUrl);
};

const fetchModel = async itrModelUrl => {
  try {
    let response = await downloadFile(itrModelUrl, { credentials: 'include' });

    return response;
  } catch (err) {
    logger
      .error('Downloading of ITR data - failed')
      .to(['analytics', 'host'])
      .data({ module: 'itr-fetcher', err })
      .end();
    return Promise.reject(err);
  }
};

export const extractItrModel = async (arraybufferData, modelUrl) => {
  try {
    logger.time('extracting  ' + modelUrl);

    let zippedModel = await getZippedObject(arraybufferData);
    const metaDataStr = await extractFile(zippedModel, 'data.json', 'string');

    const metaDataObj = JSON.parse(metaDataStr.trim());
    const model = await getModelByMetadata(metaDataObj, EXTRACTED_MODEL, zippedModel);

    const textures = await getModelTextures(zippedModel, metaDataObj);

    model.textures = textures;
    model.id = new Date().getTime();
    model.itrFileVersion = metaDataObj.version || '1.0.0';

    const timeToExtractZip = logger.timeEnd('extracting  ' + modelUrl, { module: 'itr-fetcher.service' });

    logger
      .info('Extracting of ITR data - succeeded')
      .to(['analytics', 'host'])
      .data({ module: 'itr-fetcher', timeToExtract: timeToExtractZip })
      .end();

    return model;
  } catch (err) {
    logger
      .error('Extracting of ITR data - failed')
      .to(['analytics', 'host'])
      .data({ module: 'itr-fetcher', err })
      .end();

    return Promise.reject(err);
  }
};

export const getITRModelWithPolling = async (itrModelUrl, isItrModelExistsUrl) => {
  const response = await fetchModelWithPolling(itrModelUrl, isItrModelExistsUrl);
  const arraybufferData = await response.arrayBuffer();
  return await extractItrModel(arraybufferData, itrModelUrl);
};
const fetchModelWithPolling = async (itrModelUrl, isItrModelExistsUrl) => {
  try {
    let response = await downloadFile(itrModelUrl, { credentials: 'include' });

    if (response.status === 404) {
      let hasItrCreated = await waitForItrToBeCreated(isItrModelExistsUrl);
      if (hasItrCreated) {
        response = await downloadFile(itrModelUrl, { credentials: 'include' }, true);
      }
    }

    return response;
  } catch (err) {
    logger
      .error('fetchModelWithPolling: Downloading of ITR data - failed')
      .to(['analytics', 'host'])
      .data({ module: 'itr-fetcher', err })
      .end();
    return Promise.reject(err);
  }
};
