import { Timber } from '@itero/timber';
import { LogLevel, EventType, AppenderType } from '@itero/timber/enums';
import * as configuration from '../constants/configurationValues.constants';
import { settingsManager } from '../settings-manager';
import { utils } from '../utils';

let timber = null;
let runOnce = false;

export const missingTimberUrlParamError = 'Missing timber url for timberLogger';

const getTimberInstance = () => {
  if (runOnce) {
    return timber;
  }

  const timberUrl = settingsManager.getConfigValue(configuration.timberUrl) || null;
  const companyId = settingsManager.getConfigValue(configuration.companyId) || 'NO_COMPANY_ID_PARAM';
  const userId = settingsManager.getConfigValue(configuration.userId) || 'NO_USER_ID_PARAM';

  if (!timberUrl) {
    console.error(missingTimberUrlParamError);
    runOnce = true;

    return null;
  }

  runOnce = true;

  timber = new Timber({
    appId: 'web-3d-tool',
    appenderTypes: [AppenderType.Remote],
    companyId,
    userId,
    minLogLevel: LogLevel.All,
    requiredFields: [],
    url: timberUrl,
    eventType: EventType.BusinessReport
  });

  return timber;
};

const buildTimberMessage = params => {
  const { eventName, action, module, type, actor = 'User', value } = params;
  const platform = utils.getEnv() || 'eup';

  const message = {
    eventName,
    module,
    type,
    actor,
    action,
    value: { value: value },
    platform
  };

  return message;
};

export const logToTimber = ({ timberData }) => {
  const timber = getTimberInstance();

  if (timber) {
    const message = buildTimberMessage(timberData);
    timber.log(LogLevel.All, {}, { extendedParameters: message });
  }
};
