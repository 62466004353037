import React from 'react';
import PropTypes from 'prop-types';

import styles from './TimelapseWrapper.module.css';

const TimelapseWrapper = props => {
  const { children, dismiss } = props;

  return (
    <div onClick={dismiss} className={styles.timelapseWrapper} id="timelapse-wrapper">
      {children}
    </div>
  );
};

TimelapseWrapper.propTypes = {
  /**
   * Element of children that modal contains.
   */
  children: PropTypes.element
};

TimelapseWrapper.defaultProps = {};

export default TimelapseWrapper;
