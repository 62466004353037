import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UpperJaw from './UpperJaw';
import LowerJaw from './LowerJaw';
import LowerJawToggle from './LowerJawToggle';
import UpperJawToggle from './UpperJawToggle';
import Bite from './Bite';
import PrepToggle from './PrepToggle';
import styles from './Jaws.module.css';

const propTypes = {
  /**
   * If `true`, bite is disabled
   */
  biteDisabled: PropTypes.bool,
  /**
   * If `true`, lower jaw is disabled
   */
  lowerDisabled: PropTypes.bool,
  /**
   * If `true`, lower jaw is selected
   */
  lowerSelected: PropTypes.bool,
  /**
   * Callback, fired when a jaw element is clicked
   */
  onClick: PropTypes.func.isRequired,
  /**
   * If `true`, upper jaw is disabled
   */
  upperDisabled: PropTypes.bool,
  /**
   * If `true`, upper jaw is selected
   */
  upperSelected: PropTypes.bool,
  /**
   * If `true`, jaws are 3 ways toggleable
   */
  isJawToggleExist: PropTypes.bool.isRequired,
  /**
   * 0 jaw is not selected, 1 first jaw selected, 2 - second jaw selected
   */
  upperJawToggleSelectedOption: PropTypes.oneOf(['0', '1', '2']),
  /**
   * 0 jaw is not selected, 1 first jaw selected, 2 - second jaw selected
   */
  lowerJawToggleSelectedOption: PropTypes.oneOf(['0', '1', '2']),
  /**
   * Is MultiBite enabled
   */
  isMultiBite: PropTypes.bool,
  /**
   * Is multiBite Active or not
   */
  multiBiteActive: PropTypes.bool,
  /**
   * object containing all available preps
   */
  preps: PropTypes.object
};

const defaultProps = {
  biteDisabled: false,
  lowerDisabled: false,
  lowerSelected: false,
  upperDisabled: false,
  upperSelected: false,
  upperJawToggleSelectedOption: '0',
  lowerJawToggleSelectedOption: '0',
  isMultiBite: false,
  multiBiteActive: false,
  preps: {}
};

const jawsVisibility = { isPrepsVisible: false, isJawsVisible: false };

const Jaws = props => {
  const {
    biteDisabled,
    lowerDisabled,
    lowerSelected,
    upperDisabled,
    upperSelected,
    onClick,
    isJawToggleExist,
    upperJawToggleSelectedOption,
    lowerJawToggleSelectedOption,
    isMultiBite,
    multiBiteActive,
    className: classNameProp,
    preps,
    style
  } = props;

  const isPrepsVisible = () => (Object.values(preps).find(prep => prep.checked) ? true : false);
  const isJawsVisible = () =>
    ((upperJawToggleSelectedOption === '0' && lowerJawToggleSelectedOption === '0') ||
      (upperJawToggleSelectedOption === '2' && lowerJawToggleSelectedOption === '2')) &&
    (!upperSelected && !lowerSelected)
      ? false
      : true;

  jawsVisibility.isJawsVisible = isJawsVisible();
  jawsVisibility.isPrepsVisible = isPrepsVisible();

  const upperJawProps = {
    className: styles.upperJaw,
    disabled: upperDisabled,
    selected: upperSelected,
    onClick: () => onClick('upper', props)
  };

  const lowerJawProps = {
    className: styles.lowerJaw,
    disabled: lowerDisabled,
    selected: lowerSelected,
    onClick: () => onClick('lower', props)
  };

  const upperJawToggleProps = {
    className: styles.upperJaw,
    selectedOption: upperJawToggleSelectedOption,
    onClick: () => onClick('upper_toggle', props)
  };

  const lowerJawToggleProps = {
    className: styles.lowerJaw,
    selectedOption: lowerJawToggleSelectedOption,
    onClick: () => onClick('lower_toggle', props)
  };

  const biteProps = {
    className: styles.bite,
    disabled: biteDisabled,
    isMultiBite,
    multiBiteActive,
    onClick: () => onClick('bite', props)
  };

  const className = classNames(classNameProp, styles.jaws);

  return (
    <div className={className} style={style}>
      {!isJawToggleExist && <UpperJaw {...upperJawProps} />}
      {isJawToggleExist && <UpperJawToggle {...upperJawToggleProps} />}
      <Bite {...biteProps} />
      {!isJawToggleExist && <LowerJaw {...lowerJawProps} />}
      {isJawToggleExist && <LowerJawToggle {...lowerJawToggleProps} />}

      {preps &&
        Object.values(preps).map(prep => {
          const className = classNames(styles.prepToggle, styles[`ada${prep.id}`]);
          const prepProps = {
            ...prep,
            onClick: () => onClick(`ada${prep.id}`, props),
            key: prep.id,
            className
          };

          return <PrepToggle {...prepProps} />;
        })}
    </div>
  );
};

Jaws.propTypes = propTypes;
Jaws.defaultProps = defaultProps;
Jaws.jawsVisibility = jawsVisibility;

export default Jaws;
