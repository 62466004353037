import queryString from 'query-string';
import UAParser from 'ua-parser-js';
import { get, keys } from 'lodash';
import moment from 'moment';
import * as configuration from '../constants/configurationValues.constants';
import { Environments } from '../constants/environment.constants';
import { settingsManager } from '../settings-manager';

const userAgentParser = new UAParser();
const browser = userAgentParser.getBrowser();

const getUrlParam = ({ url, param }) => {
  const urlToSearch = url || window.location.href;
  const arr = urlToSearch.split('?');
  const search = arr.length === 1 ? '' : arr[1];
  const paramsObj = queryString.parse(search);
  const lowerCaseParamObj = keys(paramsObj).reduce((acc, key) => {
    acc[key.toLowerCase()] = paramsObj[key];
    return acc;
  }, {});
  return lowerCaseParamObj[param.toLowerCase()];
};

const getIs360HubEnabled = () => {
  const layout360 = settingsManager.getConfigValue(configuration.layout360);
  return layout360 === 'true';
};

const getBrowser = () => browser;

/**
 * This function is using ua-parser-js as singlethon
 * It supposed to have a rules object which looks like this:
 * {
 *  ie:400,
 *  default:100
 * }
 * and return the value according to the current browser
 * @param {Object} rules
 */
const getValueByBrowser = rules => {
  const browserName = browser.name.toLowerCase();
  return get(rules, browserName) || rules.default || null;
};

const getAppVersion = () => process.env.APP_VERSION;

const makeCancelablePromise = promise => {
  let hasCanceled = false;
  let hasSettled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then(
        val => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
        error => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
      )
      .finally(() => {
        hasSettled = true;
      });
  });

  return {
    promise: wrappedPromise,
    cancel() {
      if (!hasSettled) {
        hasCanceled = true;
      }

      return hasCanceled;
    }
  };
};

const getEnv = () => {
  let host = utils.getUrlParam({ param: 'host' });
  return host === 'eup' ? Environments.EUP : Environments.SCANNER;
};

const calcWindowWidth = () =>
  window.innerWidth >= 1480 && window.innerWidth <= 1920 ? window.innerWidth : window.innerWidth > 1920 ? 1920 : 1480;

const formatDateAndTime = dateString => {
  const formattedDateAndTime = moment(dateString).format('D MMM YYYY,hh:mm a');
  const [formattedDate, formattedTime] = formattedDateAndTime.split(',');
  return { formattedDate, formattedTime };
};

export const utils = {
  getUrlParam,
  getIs360HubEnabled,
  getAppVersion,
  getValueByBrowser,
  getBrowser,
  makeCancelablePromise,
  getEnv,
  calcWindowWidth,
  formatDateAndTime
};
