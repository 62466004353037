import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../../Button';
import styles from './LowerJawToggle.module.css';

const propTypes = {
  /**
   * Selected toggle option
   */
  selectedOption: PropTypes.oneOf(['0', '1', '2'])
};

const defaultProps = {
  selectedOption: null,
  disabled: false,
  onClick: () => {}
};

function LowerJawToggle(props) {
  const { className: classNameProp, selectedOption, disabled, onClick } = props;
  const className = classNames(classNameProp, {
    [styles.isDefault]: selectedOption === '0',
    [styles.isOneSelected]: selectedOption === '1',
    [styles.isTwoSelected]: selectedOption === '2'
  });

  return (
    <Button
      className={className}
      disabled={disabled}
      onClick={onClick}
      data-test-id="lower-jaw-toggle"
      id="lower-jaw-toggle"
    >
      <div className={styles.after} />
    </Button>
  );
}

LowerJawToggle.propTypes = propTypes;
LowerJawToggle.defaultProps = defaultProps;

export default LowerJawToggle;
