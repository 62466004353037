import { get } from 'lodash';
import { cacheManager, cacheKeys } from '../cache-manager';
import { extractFile, getZippedObject } from '../unzip-util';
import { default as requestsManager } from '../api-requests';
import { default as logger } from '../logger';
import * as configuration from '../constants/configurationValues.constants';
import { settingsManager } from '../settings-manager';
import {
  getExistedScansObj,
  getImageCenter,
  getImagesBlobsFromZippedData,
  getImagesMetaData,
  getPanoramaData
} from './niri-manager.logic';
import * as defaultScannerType from './default-scanner-type.logic';
import * as luminaScannerType from './lumina-scanner-type.logic';

const NG_SCANNER_TYPE_NAME = 'Angie';

const setIsLuminaScannerTypeToCache = async () => {
  const zippedData = await getPhotosFileByEnv();

  if (zippedData) {
    const dataStr = await extractFile(zippedData, 'data.json', 'string');
    const dataJson = JSON.parse(dataStr);
    const scannerType = setIsNGData(dataJson);
    return scannerType === NG_SCANNER_TYPE_NAME;
  }

  return false;
};

export const setIsNGData = dataJson => {
  const scannerTypeAndVersion = get(dataJson, 'program version');
  const scannerType = scannerTypeAndVersion.match(/[a-zA-Z]+/g).join(' ');
  cacheManager.set(cacheKeys.SCANNER_TYPE, scannerType);
  return scannerType || [];
};

export const getIsLuminaScannerTypeFromCache = async () => {
  const scannerTypeName = cacheManager.get(cacheKeys.SCANNER_TYPE);
  if (scannerTypeName) return scannerTypeName === NG_SCANNER_TYPE_NAME;
  return await setIsLuminaScannerTypeToCache();
};

export const isNiriEnabled = () => {
  const isIOCEnabled = settingsManager.getConfigValue(configuration.isIOCEnabled) === 'true';
  const isEVxEnabled = settingsManager.getConfigValue(configuration.isEVxEnabled) === 'true';
  return isEVxEnabled || isIOCEnabled;
};

export const getPhotosFileByEnv = async () => {
  let niriFilePath = requestsManager.getNiriFilePath();
  if (niriFilePath) {
    try {
      const response = await requestsManager.getNiriFile();
      const arraybufferData = await response.arrayBuffer();
      return await getZippedObject(arraybufferData);
    } catch (err) {
      logger
        .error('error')
        .data({ module: 'niri-logic', err })
        .end();
    }
  }
};

export const getMouseVector = async (x, y, z, camera, width = window.innerWidth, height = window.innerHeight) => {
  return (await getIsLuminaScannerTypeFromCache())
    ? luminaScannerType.getMouseVector(x, y, z, camera, width, height)
    : defaultScannerType.getMouseVector(x, y, z, camera, width, height);
};

export const preparePhotosData = async zippedData => {
  try {
    const promises = [
      await extractFile(zippedData, 'data.json', 'string'),
      await getImagesBlobsFromZippedData(zippedData)
    ];
    const [dataStr, _imagesBlobs] = await Promise.all(promises);
    const dataJson = JSON.parse(dataStr);
    setIsNGData(dataJson);
    const existedScans = getExistedScansObj(dataJson);
    const imagesCenter = getImageCenter(dataJson);
    const upper_jaw = getImagesMetaData(dataJson, 'upper_jaw', existedScans, _imagesBlobs);
    const lower_jaw = getImagesMetaData(dataJson, 'lower_jaw', existedScans, _imagesBlobs);
    const panorama = getPanoramaData(dataJson, 'panorama', _imagesBlobs);
    const jawsPhotosMetadata = { upper_jaw, lower_jaw };
    return { existedScans, imagesCenter, jawsPhotosMetadata, panorama };
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getVectorIntersects = async (pointerVector, rayCaster, camera, meshes) => {
  return (await getIsLuminaScannerTypeFromCache())
    ? luminaScannerType.getVectorIntersects(pointerVector, rayCaster, camera, meshes)
    : defaultScannerType.getVectorIntersects(pointerVector, rayCaster, camera, meshes);
};

export const getClosestPhotoObject = async ({ jawName, intersect, imagesCenter, jawsPhotosMetadata, camera }) => {
  return (await getIsLuminaScannerTypeFromCache())
    ? luminaScannerType.getClosestPhotoObject({ jawName, intersect, imagesCenter, jawsPhotosMetadata, camera })
    : defaultScannerType.getClosestPhotoObject({ jawName, intersect, imagesCenter, jawsPhotosMetadata, camera });
};

export const getNormalizedLoupeCoords = async (centerOfLoupeCoords, containerWidth, containerHeight) => {
  return (await getIsLuminaScannerTypeFromCache())
    ? luminaScannerType.getNormalizedLoupeCoords(centerOfLoupeCoords, containerWidth, containerHeight)
    : defaultScannerType.getNormalizedLoupeCoords(centerOfLoupeCoords, containerWidth, containerHeight);
};
