import React, { useRef, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useThree } from 'react-three-fiber';

export const CameraContext = React.createContext();

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.position.toString() === nextProps.position.toString() &&
    prevProps.up.toString() === nextProps.up.toString() &&
    prevProps.resetCameraRotationOnUpdate === nextProps.resetCameraRotationOnUpdate &&
    prevProps.zoom === nextProps.zoom
  ) {
    return true;
  }
  return false;
};

const Camera = React.memo(
  React.forwardRef((props, ref) => {
    const { children, resetCameraRotationOnUpdate, ...other } = props;
    const cameraRef = useRef();
    const { setDefaultCamera } = useThree();

    useImperativeHandle(ref, () => cameraRef);

    useEffect(() => {
      setDefaultCamera(cameraRef.current);
    }, [setDefaultCamera]);

    const lightColor = 0xfffffff;
    const lightIntensity = 0.25;

    return (
      <>
        <orthographicCamera
          {...other}
          ref={cameraRef}
          onUpdate={self => {
            // resetCameraRotationOnUpdate is used to force rotation of the camera in niri and only in niri mode.
            // This is due to an improvment of activating the niri and not using the splitter to re render the canvas on the DOM
            // If we will not use the `props.onResetCameraRotation` method, we will look at a "blank" model, and we don't want to use this method beacuse we
            // will get wrong camera direction when re rendering the camera in 2-split screen and 5-split creen
            if (resetCameraRotationOnUpdate) {
              const isNiriOn = true;
              props.onResetCameraRotation(isNiriOn);
            }

            self.updateProjectionMatrix();
          }}
        >
          <directionalLight args={[lightColor, lightIntensity]} position={[0, 100, 100]} />
          <directionalLight args={[lightColor, lightIntensity]} position={[0, -100, 100]} />
          <directionalLight args={[lightColor, lightIntensity]} position={[100, 0, 100]} />
          <directionalLight args={[lightColor, lightIntensity]} position={[-100, 0, 100]} />
        </orthographicCamera>

        {cameraRef.current && <CameraContext.Provider value={cameraRef.current}>{children}</CameraContext.Provider>}
      </>
    );
  }),
  propsAreEqual
);

Camera.propTypes = {
  /**
   * Camera frustum near plane
   */
  near: PropTypes.number,
  /**
   * Camera frustum far plane
   */
  far: PropTypes.number,
  /**
   * A THREE.Vector3 representing the camera's local position
   */
  position: PropTypes.arrayOf(PropTypes.number),
  /**
   * This is used by the lookAt method
   */
  up: PropTypes.arrayOf(PropTypes.number),
  /**
   * Flag to rotate the camera by using `props.onResetCameraRotation` method only when in niri, when we
   * do not use the splitter anymore - otherwise we will NOT see the model on the screen
   */
  resetCameraRotationOnUpdate: PropTypes.bool,
  /**
   * Callback, fired when `resetCameraRotationOnUpdate is `true`
   */
  onResetCameraRotation: PropTypes.func
};

export default Camera;
