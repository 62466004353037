import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CloseIcon from './icons/CloseIcon';

import styles from './StripMenu.module.css';

const StripMenu = props => {
  const { className: classNameProp, explorers, activatedExplorerId } = props;

  const className = classNames(styles.stripMenu, classNameProp);

  return (
    <div className={className} id="strip-menu">
      <button className={styles.closeButton} id="strip-menu-close-button">
        <CloseIcon />
      </button>
      <div className={styles.explorersContainer}>
        {explorers.map(explorer => {
          const activeClass = explorer.id === activatedExplorerId ? styles.activeExplorer : styles.inactiveExplorer;
          const disabledClass = explorer.enabled ? '' : styles.disabledExplorer;
          const buttonClasses = classNames(styles.optionsContainerButton, disabledClass, activeClass);
          return (
            <button
              key={explorer.id}
              id={explorer.id}
              className={buttonClasses}
              onClick={e => {
                e.stopPropagation();
                explorer.activateExplorer();
              }}
              disabled={!explorer.enabled}
            >
              {explorer.title}
            </button>
          );
        })}
      </div>
    </div>
  );
};

StripMenu.propTypes = {
  explorers: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Explorer id
       */
      id: PropTypes.string.isRequired,
      /**
       * Call back to active explorer
       */
      activateExplorer: PropTypes.func.isRequired,
      /**
       * explorer title
       */
      title: PropTypes.string.isRequired,
      /**
       * Is explorer enabled boolean
       */
      enabled: PropTypes.bool.isRequired
    })
  ),
  activatedExplorerId: PropTypes.string.isRequired
};

StripMenu.defaultProps = {
  explorers: []
};

export default StripMenu;
