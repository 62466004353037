import React, { useEffect, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { values, cloneDeep } from 'lodash';
import { createMeshsArray, HourglassProgress, Renderer, Splitter, createPanorameMesh } from '@web-3d-tool/shared-ui';
import { shellActions, rendererActions } from '@web-3d-tool/redux-logic';
import { TOOGLE_MENU360 } from '@web-3d-tool/shared-logic/src/constants/tools.constants';
import * as configValues from '@web-3d-tool/shared-logic/src/constants/configurationValues.constants';

import {
  utils,
  logger,
  logToTimber,
  eventBus,
  globalEventsKeys,
  cacheManager,
  cacheKeys,
  settingsManager,
  requestsManager
} from '@web-3d-tool/shared-logic';
import ToolsContainer from './components/ToolsContainer';
import AppPreset from './components/Preset';
import styles from './App.module.css';

const { imperativeThreeObjectsReady, cameraStoppedMoving, twoFingersDoubleTap } = rendererActions;
const { appLoaded, setWindowSize, presetLoaded } = shellActions;

const renderStage = ({
  model,
  metadata: currentMetadata,
  materialsObject,
  stage,
  appProps,
  onMount,
  resetCameraRotationOnUpdate,
  menuType360,
  panorama
}) => {
  const metadata = cloneDeep(currentMetadata);
  [...values(metadata.lower_jaw), ...values(metadata.upper_jaw)].forEach(object => {
    if (object.material) {
      const { uuid } = object.material;
      object.material = materialsObject[uuid];
    }
  });
  const geometries = Object.values(model.objects).map(geometry => ({ geometry }));
  const meshes = createMeshsArray(model, metadata);
  const panoramaMesh = createPanorameMesh(panorama, meshes);
  const numberOfItems = stage.reduce((count, current) => count + current.length, 0);
  return (
    <Splitter
      propsMatrix={stage}
      key={numberOfItems} //we want to cause rerendering in case of spliting renderer
      renderComp={(props, rowIndex, rendererInRowIndex) => {
        const rendererProps = { meshes, panoramaMesh };
        if (rowIndex === 0 && rendererInRowIndex === 0) {
          // we are sending the three objects via redux only for the first renderer
          rendererProps.getThreeJSObjects = objects => appProps.imperativeThreeObjectsReady({ objects });
          rendererProps.onTap2Fingers = controls => eventBus.subscribeToEvent(globalEventsKeys.TAP_2_FINGERS, controls);
          rendererProps.onDoubletap2Fingers = controls => appProps.twoFingersDoubleTap(controls);
        }

        rendererProps.onCameraMove = ({ target: { camera } }) =>
          eventBus.raiseEvent(globalEventsKeys.CAMERA_CHANGED, { camera });
        rendererProps.onCameraStopMoving = ({ target: { camera } }) =>
          appProps.cameraStoppedMoving({
            camera,
            arrayIndex: rowIndex,
            inArrayIndex: rendererInRowIndex
          });

        return (
          <Renderer
            {...props}
            {...rendererProps}
            geometries={geometries}
            onMount={onMount}
            id={`renderer-${rowIndex}-${rendererInRowIndex}`}
            resetCameraRotationOnUpdate={resetCameraRotationOnUpdate}
            menuType360={menuType360}
          />
        );
      }}
    />
  );
};

const PluginsViews = ({ pluginsViews }) =>
  values(pluginsViews).map((view, index) => <Fragment key={index}>{view}</Fragment>);

const handleResizeWindow = (e, setWindowSize) => {
  const { innerWidth: width, innerHeight: height } = e.target;
  eventBus.raiseEvent(globalEventsKeys.RESIZING, { width, height });
  setWindowSize({ width, height });
};

const appVersion = utils.getAppVersion();

function logAboutAppLoaded(timeToLoad) {
  const dataToLog = {
    module: 'app',
    version: appVersion,
    modelUrl: requestsManager.getModelUrl(),
    niriUrl: requestsManager.getNiriFilePath(),
    bffUrl: settingsManager.getConfigValue(configValues.serverEndpoint),
    timeToLoad
  };

  logger
    .info('App rendered')
    .data(dataToLog)
    .to(['analytics', 'host'])
    .end();

  //Should probably remove this in 21A, as not to add to console blindness:
  console.log('Time till rendering is shown on screen: ', timeToLoad);

  logToTimber({
    timberData: {
      action: 'loaded',
      module: 'web-viewer',
      type: 'page',
      actor: 'System',
      value: timeToLoad
    }
  });
}

const App = props => {
  const {
    pluginsZones,
    isThreejsObjectsReady,
    isPresetLoaded,
    appLoaded,
    presetLoaded,
    model: modelId,
    metadata,
    pluginsViews,
    stage,
    panorama,
    resetCameraRotationOnUpdate,
    modelIsLoading,
    menuType360
  } = props;

  const [model, setModel] = useState(null);
  const [materialsObject, setMaterialsObject] = useState(null);

  const [isMounted, setIsMounted] = useState(false);
  const onMount = () => setIsMounted(true);

  const isLoading = !(isMounted && isThreejsObjectsReady && isPresetLoaded) || modelIsLoading;

  useEffect(() => {
    if (isMounted) {
      const timeToLoad = logger.timeEnd();
      logAboutAppLoaded(timeToLoad);
    }
  }, [isMounted]);

  useEffect(() => {
    appLoaded();
    window.addEventListener('resize', e => handleResizeWindow(e, props.setWindowSize));

    return () => {
      window.removeEventListener('resize', e => handleResizeWindow(e, props.setWindowSize));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setModel(cacheManager.get(cacheKeys.MODEL));
    setMaterialsObject(cacheManager.get(cacheKeys.MATERIALS));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId]);

  return (
    <div className={styles.app}>
      {model &&
        !modelIsLoading &&
        isPresetLoaded &&
        materialsObject &&
        renderStage({
          model,
          metadata,
          panorama,
          materialsObject,
          stage,
          appProps: props,
          onMount,
          resetCameraRotationOnUpdate,
          menuType360
        })}
      {pluginsZones && <AppPreset zones={pluginsZones} presetLoaded={presetLoaded} />}
      {pluginsViews && <PluginsViews pluginsViews={pluginsViews} />}

      <ToolsContainer style={{ position: 'absolute' }} />

      {isLoading && (
        <div className={styles.progress}>
          <HourglassProgress />
        </div>
      )}
      <div className={styles.appVersion} id="appVersion">
        [ {appVersion} ]
      </div>
    </div>
  );
};

const mapStateToProps = ({ renderer, shell, plugins }) => {
  const {
    model,
    metadata,
    stage,
    isThreejsObjectsReady,
    resetCameraRotationOnUpdate,
    modelIsLoading,
    panorama
  } = renderer;
  const { pluginsZones, pluginsViews, isPresetLoaded } = shell;

  const menuType360 = plugins[TOOGLE_MENU360.id].pluginParameters?.menuType;

  return {
    model,
    pluginsZones,
    metadata,
    pluginsViews,
    stage,
    panorama,
    isThreejsObjectsReady,
    isPresetLoaded,
    resetCameraRotationOnUpdate,
    modelIsLoading,
    menuType360
  };
};

export default connect(
  mapStateToProps,
  {
    appLoaded,
    presetLoaded,
    imperativeThreeObjectsReady,
    cameraStoppedMoving,
    twoFingersDoubleTap,
    setWindowSize
  }
)(App);
