import { CanvasTexture } from 'three';

const MAX_TEXTURE_SIZE = getMaxTextureSize() || 8192;
const MIN_TEXTURE_SIZE = 1;

export async function createMipmapTexture(imageDataURL, options) {
  const defaultOptions = {
    maxTextureWidth: MAX_TEXTURE_SIZE,
    maxTextureHeight: MAX_TEXTURE_SIZE
  };

  const userOptions = {
    ...defaultOptions,
    ...options
  };

  const image = await createImageFromDataURL(imageDataURL);
  const mipmaps = createMipmaps(image, userOptions);
  const texture = createCanvasTexture(mipmaps);

  return texture;
}

export function createMipmaps(image, options) {
  const { maxTextureWidth, maxTextureHeight } = options;
  const mipmaps = [];

  let nextWidth = floorPowerOfTwo(Math.min(image.width, maxTextureWidth));
  let nextHeight = floorPowerOfTwo(Math.min(image.height, maxTextureHeight));

  while (nextWidth && nextHeight) {
    const map = imageToCanvas(image, nextWidth, nextHeight);
    mipmaps.push(map);

    if (nextWidth * nextHeight === MIN_TEXTURE_SIZE) {
      break;
    } else {
      nextWidth = getSmallerPowerOfTwo(nextWidth);
      nextHeight = getSmallerPowerOfTwo(nextHeight);
    }
  }

  return mipmaps;
}

export function createCanvasTexture(mipmaps) {
  const texture = new CanvasTexture(mipmaps[0]);
  texture.mipmaps = mipmaps;

  return texture;
}

function createImageFromDataURL(dataURL) {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = event => {
      resolve(event.target);
    };

    image.onerror = event => {
      reject(event);
    };

    image.src = dataURL;
  });
}

function imageToCanvas(image, width, height) {
  const [canvas, context] = createCanvas(width, height);
  context.drawImage(image, 0, 0, width, height);

  return canvas;
}

function createCanvas(width, height) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = width;
  canvas.height = height;

  return [canvas, context];
}

function getSmallerPowerOfTwo(value) {
  return value === 1 ? value : floorPowerOfTwo(value - 1);
}

function floorPowerOfTwo(value) {
  return Math.pow(2, Math.floor(Math.log(value) / Math.LN2));
}

function getMaxTextureSize() {
  const canvas = document.createElement('canvas');

  canvas.width = 1;
  canvas.height = 1;

  // IE 11 doesn't support 'webgl'
  const possibleContextNames = ['webgl', 'experimental-webgl'];
  const contextName = possibleContextNames.find(name => Boolean(canvas.getContext(name)));
  const gl = canvas.getContext(contextName);
  const maxTextureSize = gl ? gl.getParameter(gl.MAX_TEXTURE_SIZE) : 0;

  return maxTextureSize;
}
