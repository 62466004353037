import { get } from 'lodash';
import logger from '../../logger';
import { logToTimber } from '../../timberLogger';
import * as configuration from '../../constants/configurationValues.constants';
import { cacheManager } from './cache-manager';
import { settingsManager } from '../../settings-manager';

const getFileType = url => {
  if (!url) return null;
  return url.split('.').pop();
};

const logToGAAndTimber = (url, options, isForced, howFileWasDownloaded, errorMessage = null) => {
  const fileType = getFileType(url);
  const metaData = {
    url,
    orderId: settingsManager.getConfigValue(configuration.orderId),
    options,
    isForced,
    howFileWasDownloaded
  };

  if (errorMessage) {
    metaData.errorMessage = errorMessage;
  }

  logger
    .info('Network')
    .to(['analytics', 'host'])
    .data({ fileType, ...metaData, module: 'download-manager' })
    .end();

  logToTimber({
    timberData: {
      action: 'downloading itr',
      module: 'download-manager',
      type: 'object',
      actor: 'System',
      value: metaData
    }
  });
};

export default async (url, options = {}, force = false) => {
  try {
    if (!force) {
      const cachedResponse = cacheManager.checkIfExistsInCache(url);
      if (cachedResponse && !get(cachedResponse, 'body.locked')) {
        logToGAAndTimber(url, options, force, 'from cache');
        return cachedResponse;
      }
    }
    logger.time('downloading ' + url);

    const res = await fetch(url, options);

    if (res.status === 401) {
      window.parent.postMessage(res, '*');
      const errorMsg = { errorMessage: `error while fetching url: ${url} - error: ${res.status}` };
      logToGAAndTimber(url, options, force, 'Unauthorized', errorMsg);
    }

    options.timeToDownloadMs = logger.timeEnd('downloading ' + url, { module: 'download-manager' });
    const isSuccess = res && res.status && res.status >= 200 && res.status < 300;

    if (isSuccess) {
      cacheManager.addToCache(url, res);
      logToGAAndTimber(url, options, force, 'downloaded successfully');
      return res;
    } else {
      const { status } = res;
      logToGAAndTimber(url, options, force, 'failed to download - response status is bad', { status });
      return res;
    }
  } catch (err) {
    const errMsg = { errorMessage: `error while fetching url: ${url}. ${err}` };
    logToGAAndTimber(url, options, force, 'failed to download', errMsg);
    return Promise.reject(errMsg);
  }
};
