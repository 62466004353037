import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../../Button';
import styles from './PrepToggle.module.css';

const propTypes = {
  /**
   * Id is a number between 1 to 32
   */
  id: PropTypes.number,
  /**
   * If `true`, will render as disabled
   */
  disabled: PropTypes.bool,
  /**
   * Callback, fired on clicking on jaw
   */
  onClick: PropTypes.func,
  /**
   * If `true`, will render as checked
   */
  checked: PropTypes.bool
};

function PrepToggle(props) {
  const { className: classNameProp, checked, disabled, onClick, id } = props;
  const idClass = `ada${id}`;
  const className = classNames(styles.prepToggle, styles[idClass], classNameProp, {
    [styles.isChecked]: checked,
    [styles.isDisabled]: disabled
  });
  const prepToggleId = `prep-toggle-${id}`;

  return (
    <Button className={className} disabled={disabled} onClick={onClick} data-test-id={prepToggleId} id="prep-toggle">
      <div className={styles.after} />
    </Button>
  );
}

PrepToggle.propTypes = propTypes;

export default PrepToggle;
