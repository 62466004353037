import React from 'react';

import styles from './Splitter.module.css';

const Splitter = props => {
  const { renderComp, propsMatrix } = props;

  return (
    <div className={styles.container}>
      {propsMatrix.map((propsArr, i) => (
        <div key={i} className={styles.splitterRow} style={{ height: `${100 / propsMatrix.length}%` }}>
          {propsArr.map((componentProps, j) => (
            <div style={{ width: `${100 / propsArr.length}%`, height: '100%' }} key={j}>
              {renderComp(componentProps, i, j)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
export default Splitter;
