import downloadFile from './download-file/download-file';
import logger from '../logger';
import { getITRModel } from './model-fetcher/itr-fetcher.service';
import * as configuration from '../constants/configurationValues.constants';
import { settingsManager } from '../settings-manager';
const getNiriFile = async () => {
  let niriFilePath = getNiriFilePath();
  if (niriFilePath) {
    return await downloadFile(niriFilePath, { credentials: 'include' });
  } else {
    logger
      .error('No NIRI file path')
      .data({ module: 'scannerRequests' })
      .end();
  }
};

const getNiriFilePath = () => {
  return settingsManager.getConfigValue(configuration.niri);
};

const getModelUrl = () => {
  return settingsManager.getConfigValue(configuration.model);
};

const getItrModel = async () => {
  const modelUrl = getModelUrl();
  return await getITRModel(modelUrl);
};

export const scannerRequests = {
  getNiriFile,
  getModelUrl,
  getNiriFilePath,
  getItrModel
};
