import { createStore, compose, applyMiddleware } from 'redux';

import reducer from './reducers';
// import loggerMiddleware from './core/logger.middleware';
import actionSplitterMiddleware from './core/actionSplitter.middleware';
import apiMiddleware from './core/api/api.middleware';
import rendererMiddleware from './feature/renderer/renderer.middleware';
import shellMiddleware from './feature/shell/shell.middleware';
import pluginsMiddleware from './feature/plugins/plugins.middleware';
import toolsMiddleware from './feature/tools/tools.middleware';

const coreMiddlewares = [
  actionSplitterMiddleware,
  // loggerMiddleware,
  apiMiddleware
];

const featureMiddlewares = [shellMiddleware, rendererMiddleware, pluginsMiddleware, toolsMiddleware];

const middlewares = [...coreMiddlewares, ...featureMiddlewares];

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const configureStore = () => {
  const store = createStore(reducer, enhancer);

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(reducer);
      });
    }
  }
  return store;
};

export default configureStore;
