import React from 'react';

export const MeshWithTextureMapping = props => {
  const { map, geometry, textureName } = props;

  if (!textureName.includes('texture_mapping')) {
    return null;
  }

  return (
    <mesh geometry={geometry}>
      <textureMaterial name="TextureMaterial" attach="material" texture={map} map={map} map-flipY={false} />
    </mesh>
  );
};
