import * as JSZip from 'jszip';

export const getZippedObject = arraybufferData => {
  const data = new Uint8Array(arraybufferData);
  return JSZip.loadAsync(data);
};

export const extractFile = async (zippedData, fileName, type) => {
  try {
    return await zippedData.file(fileName).async(type);
  } catch (e) {
    throw new Error(`Missing ${fileName} or corrupted data` + e);
  }
};
