import {
  Texture,
  UVMapping,
  ClampToEdgeWrapping,
  RGBAFormat,
  LinearFilter,
  MeshPhongMaterial,
  Color,
  DoubleSide
} from 'three';

import { ColorMaterial, TextureMaterial } from './shader-materials';

export const MaterialName = Object.freeze({
  chromatic: 'ChromaticMaterial'
});

export const shouldShowModelOCCTexture = modelName => !modelName.includes('ditch') && !modelName.includes('treatment');

export const createChromaticMaterial = () => {
  return new MeshPhongMaterial({
    color: new Color(1.0, 0.9, 0.8),
    emissive: new Color(0.1, 0.1, 0.1),
    shininess: 15,
    side: DoubleSide,
    name: MaterialName.chromatic
  });
};

export const createOcclusogramTexture = () => {
  const imageCanvas = document.createElement('canvas');
  const context = imageCanvas.getContext('2d');

  const PIXELS_PER_COLOR = 8;

  const palette = [
    'rgba(255,0,0,1.0)', //     Ortho: < 0      Restorative: 0-1.1
    'rgba(255,165,0,1.0)', //   Ortho: 0-0.2    Restorative: 1.1-1.3
    'rgba(255,255,0,1.0)', //   Ortho: 0.2-0.4  Restorative: 1.3-1.5
    'rgba(127,255,127,1.0)', // Ortho: 0.4-0.6  Restorative: 1.5-1.7
    'rgba(0,255,255,1.0)', //   Ortho: 0.6-0.8  Restorative: 1.7-1.9
    'rgba(100,149,237,1.0)', // Ortho: 0.8-1.0  Restorative: 1.9-2.1
    'rgba(0,0,255,1.0)', //     Ortho: > 1.0    Restorative: 2.1-
    'rgba(255,255,255,0)' //    Ortho: 1.4-1.6  Restorative: 1.4-1.6
  ];

  imageCanvas.width = PIXELS_PER_COLOR * palette.length;
  imageCanvas.height = 1;

  for (let i = 0; i < palette.length; ++i) {
    context.fillStyle = palette[i];
    context.fillRect(PIXELS_PER_COLOR * i, 0, PIXELS_PER_COLOR, imageCanvas.height);
  }

  const textureCanvas = new Texture(
    imageCanvas,
    UVMapping,
    ClampToEdgeWrapping,
    ClampToEdgeWrapping,
    LinearFilter,
    LinearFilter,
    RGBAFormat
  );

  textureCanvas.minFilter = LinearFilter;
  textureCanvas.needsUpdate = true;

  textureCanvas.name = 'occ_texture';

  return textureCanvas;
};

export const createColorMaterial = texture => {
  return new ColorMaterial(texture);
};

export const createTextureMaterial = texture => {
  return new TextureMaterial(texture);
};
