import { eupRequests } from './eup-requests';
import { scannerRequests } from './scanner-requests';
import { utils } from '../utils';
import { Environments } from '../constants/environment.constants';
import downloadFile from './download-file/download-file';

const fetchFile = (url, options = {}, force = false) => {
  return downloadFile(url, options, force);
};

const requestsManager = {
  ...(utils.getEnv() === Environments.EUP ? eupRequests : scannerRequests),
  fetchFile
};

export default requestsManager;
