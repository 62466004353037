import React, { useEffect, Fragment, useState, useMemo } from 'react';
import * as THREE from 'three';
import PropTypes from 'prop-types';

import { noop } from 'lodash';
import { Canvas, useThree } from 'react-three-fiber';

import { eventBus, getOpacityForCameraDirection, globalEventsKeys } from '@web-3d-tool/shared-logic';
import { menuTypes360 } from '@web-3d-tool/shared-logic/src/constants/menuTypes360.constants';
import { ZoomConstants } from '@web-3d-tool/shared-logic/src/constants/camera.constants';
import { Camera, Controls, useCamera } from './Camera';
import Scene from './Scene';

import styles from './Renderer.module.css';

const Renderer = props => {
  const {
    cameraProps,
    meshes,
    panoramaMesh,
    ignoreModels,
    getThreeJSObjects,
    onCameraMove,
    onCameraStopMoving,
    geometries,
    onTap2Fingers,
    onDoubletap2Fingers,
    id,
    onMount,
    resetCameraRotationOnUpdate,
    menuType360
  } = props;

  const isOnLanding = useMemo(() => menuType360 === menuTypes360.CIRCULAR, [menuType360]);
  const zoomParameter = useMemo(
    () => (isOnLanding ? ZoomConstants.LANDING_PAGE_MODEL_ZOOM : ZoomConstants.DEFAULT_MODEL_ZOOM),
    [isOnLanding]
  );

  const {
    camera,
    cameraControls,
    cameraRef,
    cameraControlsRef,
    resetCameraPosition,
    zoomCameraTo,
    setStaticMode
  } = useCamera(cameraProps.position, cameraProps.up, zoomParameter);

  const [panoramaObj, setPanoramaMesh] = useState(panoramaMesh);

  const setMeshOpacity = (camera, mesh) => {
    setPanoramaMesh(currentMesh => {
      if (camera) {
        const opacity = getOpacityForCameraDirection(camera);
        const meshToupdate = currentMesh || mesh;
        meshToupdate && meshToupdate.material && (meshToupdate.material.opacity = opacity);
        return meshToupdate;
      }
    });
  };

  useEffect(() => {
    panoramaMesh ? setMeshOpacity(camera, panoramaMesh) : setPanoramaMesh(panoramaMesh);
  }, [panoramaMesh, camera]);

  useEffect(() => {
    isOnLanding && eventBus.raiseEvent(globalEventsKeys.RESET_RENDERING_STAGE);
    resetCameraPosition(meshes);
    setStaticMode(isOnLanding);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnLanding, setStaticMode]);

  if (!meshes) return null;

  const filteredMeshes = meshes.filter(({ modelName }) => !ignoreModels.includes(modelName));

  const onSceneMount = () => {
    zoomCameraTo(geometries);
    onMount();
    setStaticMode(isOnLanding);
  };

  const ImperativeComp = ({ getThreeJSObjects }) => {
    // this component is a shadow component that enable us to use
    // useThree() to be able to pull out the imperative objects and send it to

    const controller = new AbortController();
    const { scene, size, canvas, gl } = useThree();

    useEffect(() => {
      const [camera, group] = scene.children;
      getThreeJSObjects && getThreeJSObjects({ scene, camera, group, size, canvas, controls: cameraControls });

      const setWindowSize = () => {
        gl.setSize(window.innerWidth, window.innerHeight);
        gl.render(scene, camera);
      };

      const setCurrentSize = () => {
        const currentSize = gl.getSize(new THREE.Vector2());
        gl.setSize(currentSize.width, currentSize.height);
        gl.render(scene, camera);
      };

      window.addEventListener('resize', setWindowSize, { signal: controller.signal });
      document.addEventListener('visibilitychange', setCurrentSize, { signal: controller.signal });

      return () => {
        controller.abort();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getThreeJSObjects, canvas, scene, size, cameraControls]);

    return <Fragment />;
  };
  return (
    <Canvas
      invalidateFrameloop
      pixelRatio={window.devicePixelRatio}
      className={isOnLanding ? styles.rendererLanding : styles.renderer}
      gl={{ preserveDrawingBuffer: true }}
      id={id}
    >
      <ImperativeComp getThreeJSObjects={getThreeJSObjects} />

      <Camera
        ref={cameraRef}
        near={1}
        far={1500}
        position={cameraProps.position}
        up={cameraProps.up}
        zoom={zoomParameter}
        resetCameraRotationOnUpdate={resetCameraRotationOnUpdate}
        onResetCameraRotation={isNiriOn => zoomCameraTo(geometries)} //TODO - fix this! niri is always true...
      >
        <Controls
          ref={cameraControlsRef}
          enabled={true}
          dynamicDampingFactor={0.3}
          staticMoving={true}
          handleTap2Fingers={controls => {
            zoomCameraTo(meshes);
            const target = controls;
            onCameraMove({ target });
            onCameraStopMoving({ target });
            onTap2Fingers(controls);
          }}
          handleDoubletap2Fingers={controls => {
            resetCameraPosition(meshes);
            const target = controls;
            onCameraMove({ target });
            onCameraStopMoving({ target });
            onDoubletap2Fingers(controls);
          }}
          onChange={({ target }) => {
            onCameraMove({ target });
            setMeshOpacity(target.camera, panoramaObj);
          }}
          onEnd={onCameraStopMoving}
          isStaticMode={isOnLanding}
        />
      </Camera>

      {camera && cameraControls && (
        <>
          <Scene meshes={filteredMeshes} onMount={onSceneMount} />
          {panoramaObj && (
            <scene name="panorama">
              <mesh name="panoramaMesh" {...panoramaObj} />
            </scene>
          )}
        </>
      )}
    </Canvas>
  );
};

Renderer.propTypes = {
  /**
   * Camera options
   */
  cameraProps: PropTypes.shape({
    /**
     * Camera frustum near plane
     */
    near: PropTypes.number,
    /**
     * Camera frustum far plane
     */
    far: PropTypes.number,
    /**
     * A Vector3 representing the camera's local position
     */
    position: PropTypes.arrayOf(PropTypes.number),
    /**
     * This is used by the lookAt method
     */
    up: PropTypes.arrayOf(PropTypes.number)
  }),
  /**
   * Meshes to render
   */
  meshes: PropTypes.arrayOf(PropTypes.number),
  /**
   * Textures
   */
  textures: PropTypes.arrayOf(PropTypes.object),
  ignoreModels: PropTypes.arrayOf(PropTypes.string),
  /**
   * Enable to get the three js imperative objects
   * e.g: scene, camera, group, etc.
   */
  getThreeJSObjects: PropTypes.func,
  onCameraMove: PropTypes.func,
  onCameraStopMoving: PropTypes.func,
  onTap2Fingers: PropTypes.func,
  onDoubletap2Fingers: PropTypes.func
};

Renderer.defaultProps = {
  ignoreModels: [],
  onCameraMove: noop,
  onCameraStopMoving: noop
};

export default Renderer;
