/**
 * @param {string} pluginId - Is the string that represents the plugin id.
 * @returns {object} - The return object is the redux action we want to instantiate with the pluginId.
 */
export const initActionCreator = pluginId => ({ type, payload }) => {
  return {
    type,
    payload,
    meta: {
      feature: '[PLUGINS]',
      plugin: pluginId
    }
  };
};
