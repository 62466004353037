import { get } from 'lodash';

const cache = {};

const checkIfExistsInCache = url => {
  const cachedResponse = get(cache, url);

  if (cachedResponse) {
    return cachedResponse.clone();
  }
  return null;
};

const addToCache = (url, res) => {
  cache[url] = res.clone();
};

export const cacheManager = {
  checkIfExistsInCache,
  addToCache
};
