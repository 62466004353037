class ZoomTransform {
  constructor(scale) {
    this.scale = scale;
  }
}

function clipScale(scale, minScale, maxScale) {
  if (scale < minScale) return minScale;
  else if (scale > maxScale) return maxScale;
  else return scale;
}

class WheelZoomGenerator {
  constructor(camera, zoomMin, zoomMax, wheelZoomSpeed) {
    this.wheelZoomSpeed = wheelZoomSpeed;
    this.zoomMin = zoomMin;
    this.zoomMax = zoomMax;
    this.camera = camera;
    this.reset();
  }

  update({ wheelDelta }) {
    this.totalWheelDelta += wheelDelta;
    const { wheelZoomSpeed, totalWheelDelta, minScale, maxScale } = this;
    let scale = Math.pow(1.0 - wheelZoomSpeed, totalWheelDelta);
    return new ZoomTransform(clipScale(scale, minScale, maxScale));
  }

  reset() {
    this.totalWheelDelta = 0;
    this.minScale = this.zoomMin / this.camera.zoom;
    this.maxScale = this.zoomMax / this.camera.zoom;
  }
}

class PointerZoomGenerator {
  constructor({ y }, camera, zoomMin, zoomMax, pointerZoomSpeed) {
    this.pointerZoomSpeed = pointerZoomSpeed;
    this.minScale = zoomMin / camera.zoom;
    this.maxScale = zoomMax / camera.zoom;
    this.startY = y;
  }

  update({ y }) {
    const { pointerZoomSpeed, minScale, maxScale, startY } = this;
    let scale = Math.pow(1.0 - pointerZoomSpeed, y - startY);
    return new ZoomTransform(clipScale(scale, minScale, maxScale));
  }
}

class WheelZoomApplier {
  constructor(generator) {
    this.generator = generator;
    this.camera = generator.camera;
  }

  updateCamera(zoomTransform) {
    if (isNaN(zoomTransform.scale)) {
      return;
    }

    this.camera.zoom *= zoomTransform.scale;
    this.camera.updateProjectionMatrix();
    this.generator.reset();
  }
}

class PointerZoomApplier {
  constructor(camera) {
    this.camera = camera;
    this.initialZoom = camera.zoom;
  }

  updateCamera(zoomTransform) {
    this.camera.zoom = this.initialZoom * zoomTransform.scale;
    this.camera.updateProjectionMatrix();
  }
}

export { PointerZoomApplier, PointerZoomGenerator, WheelZoomApplier, WheelZoomGenerator };
