import { IncomingMessagesKeys } from '../constants';

export const defaultPluginEvents = {
  DESTROY: 'DESTROY',
  STATE_CHANGED: 'STATE_CHANGED',
  LOADING_STATE_CHANGED: 'LOADING_STATE_CHANGED',
  PARAMETERS_CHANGED: 'PARAMETERS_CHANGED',
  PLUGIN_AVAILABILITY_CHANGED: 'PLUGIN_AVAILABILITY_CHANGED'
};

export const globalEventsKeys = {
  MODEL_LOADED: 'modelLoaded',
  APP_LOADED: 'appLoaded',
  NIRI_LOADED: 'niriLoaded',
  MODEL_CHANGED: 'modelChanged',
  LAYOUT_CHANGED: 'layoutChanged',
  POINTER_MOVE: 'pointerMove',
  CAMERA_CHANGED: 'cameraChanged',
  CAMERA_STOPPED_MOVING: 'cameraStoppedMoving',
  TAP_2_FINGERS: 'tap2Fingers',
  DOUBLE_TAP_2_FINGERS: 'doubleTap2Fingers',
  CAMERA_FREEZE: 'cameraFreeze',
  CAMERA_UNFREEZE: 'cameraUnFreeze',
  POINTER_START: 'pointerStart',
  POINTER_STOP: 'pointerStop',
  TEXTURE_CHANGED: 'textureChanged',
  METADATA_CHANGED: 'metadataChanged',
  VISIBILITY_CHANGED: 'visibilityChanged',
  JAWS_CHANGED: 'jawsChanged',
  RESIZING: 'resizing',
  MULTIBITE_CHANGED: 'MULTIBITE_CHANGED',
  RESET_RENDERING_STAGE: 'RESET_RENDERING_STAGE',
  JAWS_TOGGLE_MODE_CHANGED: 'JAWS_TOGGLE_MODE_CHANGED',
  OUT_OF_FOCUS: 'OUT_OF_FOCUS'
};

export const toolsEvents = {
  DRAGGABLE_OBJECT: {
    ON_START: 'draggable_object.onStart',
    ON_DRAG: 'draggable_object.onDrag',
    ON_STOP: 'draggable_object.onStop'
  }
};

export const hostEvents = { ...IncomingMessagesKeys };

export const hostEventsErrors = {
  DATA_IS_NOT_VALID: 'DATA_IS_NOT_VALID'
};
