import { Vector3, Matrix4 } from 'three';

import { CameraParams } from './camera-params';

class OrbitTransform {
  constructor(angle, axis) {
    this.angle = angle;
    this.axis = axis;
  }
}

class OrbitGenerator {
  constructor(orbitSpeed, { x, y }) {
    this.startPtInPixels = { x, y };
    this.orbitSpeed = orbitSpeed;
  }

  update({ x, y }) {
    const dx = x - this.startPtInPixels.x;
    const dy = y - this.startPtInPixels.y;
    const len = Math.sqrt(dx * dx + dy * dy);

    if (len === 0) {
      return new OrbitTransform(0, [1, 0]);
    } else {
      return new OrbitTransform(len * this.orbitSpeed, [-dy / len, -dx / len]);
    }
  }
}

class OrbitApplier {
  constructor(camera, { left, width, top, height }, centerOfOrbitInWorldCoors) {
    this.initialCameraParams = new CameraParams(camera);
    this.camera = camera;
    this.screen = { left, width, top, height };
    const { x, y, z } = centerOfOrbitInWorldCoors;
    this.centerTransform = new Matrix4().makeTranslation(x, y, z);
    this.inverseCenterTransform = new Matrix4().makeTranslation(-x, -y, -z);
  }

  _mapDirectionInPixelsToWorldCoors([dx, dy]) {
    const { width, height } = this.screen;
    const { unprojectMatrix } = this.initialCameraParams;
    return new Vector3(dx / width, dy / height, 0).transformDirection(unprojectMatrix);
  }

  _calcOrbitTransformInWorldCoors(orbitTransform) {
    const axisInWorldCoors = this._mapDirectionInPixelsToWorldCoors(orbitTransform.axis);
    return new Matrix4()
      .makeRotationAxis(axisInWorldCoors, orbitTransform.angle)
      .multiply(this.inverseCenterTransform)
      .premultiply(this.centerTransform);
  }

  _calcUpdatedCamera(orbitTransformInWorldCoors) {
    const { initialCameraParams } = this;
    let position = initialCameraParams.position.clone().applyMatrix4(orbitTransformInWorldCoors);
    let up = initialCameraParams.up.clone().transformDirection(orbitTransformInWorldCoors);
    let viewingDirection = initialCameraParams.viewingDirection.clone().transformDirection(orbitTransformInWorldCoors);
    const target = new Vector3().addVectors(position, viewingDirection);
    return { position, target, up };
  }

  updateCamera(orbitTransform) {
    if (orbitTransform.angle === 0) {
      return;
    }
    const orbitTransformInWorldCoors = this._calcOrbitTransformInWorldCoors(orbitTransform);
    const { position, target, up } = this._calcUpdatedCamera(orbitTransformInWorldCoors);
    this.camera.position.copy(position);
    this.camera.up = up;
    this.camera.lookAt(target);
  }
}

export { OrbitApplier, OrbitGenerator };
