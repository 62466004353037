import { get, set } from 'lodash';

window.appCache = {};

const cacheManager = {
  get: key => {
    return get(window.appCache, key);
  },

  set: (key, value) => {
    set(window.appCache, key, value);
  }
};

const cacheKeys = {
  MATERIALS: 'materials',
  MODEL: 'model',
  VISIBILITY_OBJECT: 'visibilityObject',
  NIRI_DATA: 'niriData',
  PRESET: 'preset',
  THREE_OBJECTS: 'threeObjects',
  CONFIGURATION: 'configuration',
  SCANNER_TYPE: 'scanner_type'
};

export { cacheManager, cacheKeys };
