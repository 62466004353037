import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { utils } from '@web-3d-tool/shared-logic';
import styles from './TimelapseContainer.module.css';
import CloseIcon from './assets/Close.svg';

const Card = ({ order, selectedOrderId, handleOnSelectOrder, currentScanRef, strings }) => {
  const { OrderId, ScanDate, IsCurrentOrder } = order;
  const { formattedDate, formattedTime } = utils.formatDateAndTime(ScanDate);

  return (
    <div
      className={`${styles.card} ${selectedOrderId === OrderId && styles.active_scan} ${
        IsCurrentOrder ? styles.current_scan : ''
      }`}
      onClick={() => handleOnSelectOrder(OrderId)}
      ref={IsCurrentOrder ? currentScanRef : null}
    >
      <div>
        {selectedOrderId === OrderId && <p className={styles.compare_with}>{strings?.timelapse.compare_with}</p>}
        {IsCurrentOrder && <p>{strings?.timelapse.timelapse_current?.toUpperCase()}</p>}
        <p className={styles.date}>{formattedDate}</p>
      </div>
      <div>
        <label className={styles.time}>{formattedTime}</label>
      </div>
    </div>
  );
};

const TimelapseContainer = ({
  className: classNameProp,
  ordersMetaData,
  selectedOrderId,
  handleOnSelectOrder,
  handleOnClickCompare,
  handleOnClickClose,
  strings
}) => {
  const className = classNames(styles.container, classNameProp);

  const scrollRef = useRef(null);
  const currentScanRef = useRef(null);

  return (
    <div className={`${className}`} onClick={e => e.stopPropagation()} id="timelapse-container">
      <div className={styles.title}>
        <div />
        <div className={styles.title_string}>{strings?.pluginsNames.timelapse}</div>
        <div className={styles.close_icon_container}>
          <img onClick={() => handleOnClickClose()} className={styles.close_icon} src={CloseIcon} alt="Close" />
        </div>
      </div>
      <div className={styles.compare_bar}>
        <p className={styles.compare_bar_text}>
          {strings?.timelapse.timelapse_choose_record
            .split(' ')
            .slice(0, 3)
            .join(' ')}
          <br />
          {strings?.timelapse.timelapse_choose_record
            .split(' ')
            .slice(3)
            .join(' ')}
        </p>
      </div>
      <div className={styles.container_buttons}>
        <div className={`${styles.scan_list}`} ref={scrollRef}>
          {[...ordersMetaData].reverse().map(order => (
            <Card
              key={order.OrderId}
              order={order}
              selectedOrderId={selectedOrderId}
              handleOnSelectOrder={handleOnSelectOrder}
              currentScanRef={currentScanRef}
              strings={strings}
            />
          ))}
        </div>
      </div>
      <button
        disabled={selectedOrderId ? false : true}
        className={`${styles.compare} ${selectedOrderId ? styles.comapare_active : ''}`}
        onClick={handleOnClickCompare}
      >
        {strings?.timelapse.compare}
      </button>
    </div>
  );
};

TimelapseContainer.propTypes = {
  /**
   * list of orders displaying in the dropdown
   */
  ordersMetaData: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Order id
       */
      OrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      /**
       * Scan date
       */
      ScanDate: PropTypes.string.isRequired,
      /**
       * Is Current Order
       */
      IsCurrentOrder: PropTypes.bool.isRequired
    })
  ),
  /**
   * Selected order id that will be marked
   */
  selectedOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Callback to change selected order
   */
  handleOnSelectOrder: PropTypes.func,
  /**
   * Callback to open timelapse with selected order
   */
  handleOnClickApply: PropTypes.func
};

TimelapseContainer.defaultProps = {
  ordersMetaData: [],
  selectedOrderId: ''
};

export default TimelapseContainer;
