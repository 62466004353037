import React from 'react';
import PropTypes from 'prop-types';

import styles from './Modal.module.css';

const Modal = props => {
  const { children, dismiss } = props;

  return (
    <div onClick={dismiss} className={styles.modal} id="modal">
      {children}
    </div>
  );
};

Modal.propTypes = {
  /**
   * Callback to open deactivate plugin
   */
  dismiss: PropTypes.func,
  /**
   * Element of children that modal contains.
   */
  children: PropTypes.element
};

Modal.defaultProps = {};

export default Modal;
