import * as THREE from 'three';
import { get, isEmpty, keys, cloneDeep } from 'lodash';

import { TeethNumbersConstants, PREP_PREFIX, ObjectSuffix, ObjectsKeys } from './../constants/model.constants';

export const isOCCExistsInGeometry = geometry => {
  return checkIfAttributeExistInGeometry(geometry, 'uv');
};

export const isOCCExistsInModel = objects => {
  const isOCCExists = Object.values(objects).reduce((acc, geometry) => {
    acc = acc || isOCCExistsInGeometry(geometry);
    return acc;
  }, false);
  return isOCCExists;
};

export const isColorExistsInGeometry = geometry => {
  return Boolean(geometry && geometry.attributes && geometry.attributes.color && geometry.attributes.color.count > 0);
};

export const isTextureMappingExistInModel = objects => {
  const isTMExists = Object.values(objects).reduce((acc, geometry) => {
    acc = acc || isTextureMappingExistInGeometry(geometry);
    return acc;
  }, false);

  return isTMExists;
};

export const isTextureMappingExistInGeometry = geometry => {
  return Boolean(geometry.attributes && geometry.attributes.uvTexture && geometry.attributes.uvTexture.count > 0);
};

export const isTextureMappingExistInTextures = textures => {
  const isTextureMappingExist = Object.values(textures).reduce((acc, texture) => {
    acc = acc || texture.name.includes('texture_mapping');
    return acc;
  }, false);
  return isTextureMappingExist;
};

export const checkIfAttributeExistInGeometry = (geometry, attributeName) => {
  const uvArr = get(geometry, `attributes.${attributeName}`) || null;

  if (!uvArr) {
    return false;
  }

  // using a for loop and break if we get 2 unique values, no need
  // to look at the rest of the array
  const uniqueValues = [];
  for (let i = 0; i < uvArr.array.length; i++) {
    if (uvArr.array[i] !== 0 && !uniqueValues.includes(uvArr.array[i])) {
      uniqueValues.push(uvArr.array[i]);
    }

    if (uniqueValues.length === 2) {
      break;
    }
  }

  return uniqueValues.length > 1;
};

export const isObjectExists = (objects, objName) => objName && objects && objName in objects;

export const isObjectHasGeometry = (objects, objName) => {
  const geometry = get(objects, objName);
  return geometry && !isEmptyGeometry(geometry);
};

export const isObjectEmpty = (objects, objName) => objName && objects && isEmpty(objects[objName]);
export const isUpperJawEnable = objects => {
  if (!objects) return false;

  return (
    isUpperDefaultJawExists(objects) || isAnyPrepExistsInUpperJaw(objects) || isUpperPretreatmentJawExists(objects)
  );
};

export const isLowerJawEnable = objects => {
  if (!objects) return false;

  return (
    isLowerDefaultJawExists(objects) || isAnyPrepExistsInLowerJaw(objects) || isLowerPretreatmentJawExists(objects)
  );
};

export const isUpperDefaultJawExists = objects => isObjectExists(objects, ObjectsKeys.UPPER_JAW);

export const isLowerDefaultJawExists = objects => isObjectExists(objects, ObjectsKeys.LOWER_JAW);

export const isUpperPretreatmentJawExists = objects => isObjectExists(objects, ObjectsKeys.UPPER_PRETREATMENT_JAW);

export const isLowerPretreatmentJawExists = objects => isObjectExists(objects, ObjectsKeys.LOWER_PRETREATMENT_JAW);

export const isAnyPretreatmentJawExists = objects =>
  isUpperPretreatmentJawExists(objects) || isLowerPretreatmentJawExists(objects);

const isAnyPrepExistsInUpperJaw = objects => isAnyPrepExistsInJaw(objects, true);
const isAnyPrepExistsInLowerJaw = objects => isAnyPrepExistsInJaw(objects, false);

export const isAnyPrepExistsInJaw = (objects, isUpper) => {
  var { fromAdaId, toAdaId } = isUpper
    ? {
        fromAdaId: TeethNumbersConstants.UPPER_JAW_FIRST_TEETH_NUMBER,
        toAdaId: TeethNumbersConstants.UPPER_JAW_LAST_TEETH_NUMBER
      }
    : {
        fromAdaId: TeethNumbersConstants.LOWER_JAW_FIRST_TEETH_NUMBER,
        toAdaId: TeethNumbersConstants.LOWER_JAW_LAST_TEETH_NUMBER
      };

  for (let adaId = fromAdaId; adaId <= toAdaId; adaId++) {
    if (isObjectHasGeometry(objects, ObjectsKeys.Preps[adaId].Prep)) {
      return true;
    }
  }
  return false;
};

export const isEmptyGeometry = geometry => {
  if (!get(geometry, 'computeBoundingBox')) return true;
  geometry.computeBoundingBox();
  const boundingBox = geometry.boundingBox;
  if (boundingBox === null) return true;
  const zeroVector = new THREE.Vector3();
  return boundingBox.min.equals(zeroVector) && boundingBox.max.equals(zeroVector);
};

export const parseMarginLineGeometry = str => {
  if (str === undefined || typeof str !== 'string') return null;
  const lines = str.split('\n');
  const numOfLines = parseInt(lines[0], 10);
  if (lines.length < numOfLines) return null;

  const pts = getMarginLinePoints(lines, numOfLines);
  const rectShape = getRectShape();
  const curvesPath = new THREE.CatmullRomCurve3(pts);

  const extrudeSettings = {
    steps: numOfLines,
    bevelEnabled: false,
    extrudePath: curvesPath
  };

  const geometry = new THREE.ExtrudeGeometry(rectShape, extrudeSettings);
  const lineColor = new THREE.Color(0xff0000);

  return new THREE.Mesh(geometry, new THREE.LineBasicMaterial({ color: lineColor }));
};

export const getMarginLinePoints = (lines, numOfLines) => {
  if (!lines || !numOfLines) return [];
  const pts = [];
  for (let i = 1; i <= numOfLines; i++) {
    const pointsArray = lines[i].split(' ');
    pts.push(new THREE.Vector3(Number(pointsArray[0]), Number(pointsArray[1]), Number(pointsArray[2])));
  }
  return pts;
};

export const getRectShape = (side = 0.025) => {
  if (typeof side !== 'number') return null;
  const rectShape = new THREE.Shape();
  rectShape.moveTo(0, 0);
  rectShape.lineTo(0, side);
  rectShape.lineTo(side, side);
  rectShape.lineTo(side, -1 * side);
  rectShape.lineTo(-1 * side, -1 * side);
  rectShape.lineTo(-1 * side, side);
  rectShape.lineTo(0, side);
  rectShape.lineTo(0, 0);
  return rectShape;
};

export const getPrepsFromModels = objects => {
  const prepsInModel = {};
  if (typeof objects !== 'object') return prepsInModel;
  Object.keys(objects).forEach(key => {
    if (!key.includes(PREP_PREFIX)) return;
    let prepId = parseInt(key.replace(PREP_PREFIX, ''));
    if (prepsInModel[PREP_PREFIX + prepId]) {
      return;
    }
    prepsInModel[PREP_PREFIX + prepId] = {
      id: prepId,
      checked: false,
      disabled: isEmpty(objects[PREP_PREFIX + prepId])
    };
  });
  return prepsInModel;
};

export const isAnyPrepsExists = objects => isAnyPrepExistsInLowerJaw(objects) || isAnyPrepExistsInUpperJaw(objects);

export const getAvailableSuffixForPrep = prepPrefix => {
  if (!prepPrefix) return [];
  return [
    `${prepPrefix}${ObjectSuffix.ADJACENT}`,
    `${prepPrefix}${ObjectSuffix.DITCH}${ObjectSuffix.INNER}`,
    `${prepPrefix}${ObjectSuffix.DITCH}${ObjectSuffix.OUTER}`,
    `${prepPrefix}${ObjectSuffix.MARGIN_LINE}`,
    prepPrefix
  ];
};

export const getObjectsKeysBySuffix = (objects, objectType) => {
  if (!objects || !objectType) {
    return [];
  }
  return keys(objects).reduce((acc, modelObject) => {
    modelObject.includes(objectType) && acc.push(modelObject);
    return acc;
  }, []);
};

export const getCameraPosByCaseType = (caseType, side) => {
  const positions = {
    ortho: {
      u: { position: [0, 0, 200], up: [0, -1, 0] },
      n: { position: [0, 0, -200], up: [0, 0, -1] },
      front: { position: [0, 200, 0], up: [0, 0, 1] },
      left: { position: [-100, 100, 0], up: [0, 0, 1] },
      right: { position: [100, 100, 0], up: [0, 0, 1] }
    },
    resto: {
      u: { position: [0, 0, 200], up: [0, 1, 0] },
      n: { position: [0, 50, -200], up: [0, 0, -1] },
      front: { position: [0, -200, 0], up: [0, 0, 1] },
      left: { position: [100, 0, 0], up: [0, 0, 1] },
      right: { position: [-100, 0, 0], up: [0, 0, 1] }
    }
  };
  return get(positions, `${caseType}.${side}`);
};

export const unCheckAllPreps = preps => {
  const newPrepsState = cloneDeep(preps);

  keys(newPrepsState).forEach(key => {
    newPrepsState[key].checked = false;
  });

  return newPrepsState;
};
