/**
 * this component was originally taken from:
 * https://github.com/mrdoob/three.js/blob/r108/examples/jsm/lines
 */

import { LineSegmentsExtended } from './LineSegmentsExtended.js';
import { LineGeometry } from './LineGeometry.js';
import { LineMaterial } from './LineMaterial';

const LineExtended = function(geometry, material) {
  LineSegmentsExtended.call(this);

  this.type = 'LineExtended';

  this.geometry = geometry !== undefined ? geometry : new LineGeometry();
  this.material = material !== undefined ? material : new LineMaterial({ color: Math.random() * 0xffffff });
};

LineExtended.prototype = Object.assign(Object.create(LineSegmentsExtended.prototype), {
  constructor: LineExtended,

  isLineExtended: true,

  copy: function() {
    return this;
  }
});

export { LineExtended };
