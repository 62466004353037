import React, { Fragment, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { logToTimber } from '@web-3d-tool/shared-logic';
import { get, set } from 'lodash';
import ImageFrameManipulation from '../ImageFrameManipulation';
import stylesNo360 from './ImageFramesContainer.module.css';
import styles360 from './ImageFramesContainer360.module.css';

const ImageFramesContainer = ({
  width,
  height,
  sourcesArr,
  enlargeWidth,
  enlargeHeight,
  showEnlargeButton,
  className: classNameProp,
  rotation,
  isDebugEnabled,
  verticalSpace,
  is360
}) => {
  const styles = is360 ? styles360 : stylesNo360;
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [frameSize, setFrameSize] = useState({ width: 0, height: 0 });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerDisabled, setIsDrawerDisabled] = useState(true);
  const [imageProps, setImageProps] = useState({ left: 0, top: 0, scale: 1 });
  const shouldShowDrawer = useMemo(() => !sourcesArr.some(source => source.isPlaceHolderIcon), [sourcesArr]);
  const [slidersValues, setslidersValues] = useState({});
  const [enlargedImage, setEnlargedImage] = useState(null);
  const margin_frames = is360 ? 16 : 0;
  const DRAWER_WIDTH = 38;
  useEffect(() => {
    if (enlargedImage) {
      setContainerSize({ width: enlargeWidth, height: enlargeHeight });
    } else {
      setContainerSize({ width, height: height });
    }
  }, [height, enlargedImage, width, enlargeWidth, enlargeHeight, margin_frames]);

  useEffect(() => {
    const calcFrameSize = (containerWidth, containerHeight, length) => {
      const numberOfFrames = enlargedImage ? 1 : length;
      const margin = verticalSpace / numberOfFrames;
      const SLIDER_WIDTH = DRAWER_WIDTH + 32; //The size of the slider (38px) and slide (32px)

      const frameWidth = containerWidth - SLIDER_WIDTH;
      let frameHeight = numberOfFrames === 1 ? containerHeight : (containerHeight - margin) / numberOfFrames;
      if (!isFinite(frameHeight)) {
        frameHeight = 0;
      }

      return { width: frameWidth - margin_frames, height: frameHeight };
    };

    const { width, height } = containerSize;
    const calculatedFrameSize = calcFrameSize(width, height, sourcesArr.length);

    setFrameSize(calculatedFrameSize);

    setIsDrawerDisabled(sourcesArr.length === 0);
    sourcesArr.length === 0 && setIsDrawerOpen(false);
  }, [containerSize, sourcesArr, enlargedImage, margin_frames, verticalSpace]);

  useEffect(() => {
    setEnlargedImage(currentType => {
      if (currentType) {
        const isExistsInArray = sourcesArr.findIndex(item => item.type === currentType) !== -1;
        return !isExistsInArray ? null : currentType;
      }
      return currentType;
    });
  }, [sourcesArr]);

  useEffect(() => {
    !shouldShowDrawer && setEnlargedImage(null);
  }, [shouldShowDrawer]);

  const toggleDrawer = () => {
    if (isDrawerDisabled) return;
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleEnlarge = type => {
    setEnlargedImage(currentType => (currentType === type ? null : type));

    logToTimber({
      timberData: {
        eventName:
          type === 'niri'
            ? 'enlarge NIRI review panel - click-web viewer'
            : 'enlarge IOC review panel - click-web viewer',
        action: 'click',
        module: type === 'niri' ? 'enlarge NIRI review panel' : 'enlarge IOC review panel',
        type: 'button',
        value: !enlargedImage ? 'toggle on' : 'toggle off'
      }
    });
  };

  const handleChange = ({ scale, top, left }) => {
    setImageProps({ scale, top, left });
  };

  const { scale, top, left } = imageProps;

  const className = classNames(classNameProp, styles.container);

  return (
    <div style={containerSize} className={className}>
      {shouldShowDrawer && (
        <div
          className={classNames(styles.drawer, {
            [styles.drawer_open]: isDrawerOpen
          })}
          onClick={toggleDrawer}
          data-test-id="drawer"
          id="drawer"
        >
          <i className={classNames(styles.arrow, { [styles.arrow_disabled]: isDrawerDisabled })} />
        </div>
      )}
      <div
        className={classNames(styles.main, shouldShowDrawer ? styles.imagesContainer : styles.placeholdersContainer)}
      >
        {sourcesArr.map(({ src, type, url, isPlaceHolderIcon }, index) => (
          <Fragment key={index}>
            {(enlargedImage === null || enlargedImage === type) && (
              <>
                {!isPlaceHolderIcon ? (
                  <div className={classNames(styles.enlarg)}>
                    {isDebugEnabled && <div className={styles.imgName}>{url}</div>}
                    {!!showEnlargeButton && (
                      <div
                        className={styles.enlarg_toggle}
                        onClick={() => {
                          toggleEnlarge(type);
                        }}
                        data-test-id={`enlarge-${index}`}
                        data-icon={enlargedImage ? 'shrink' : 'enlarge'}
                        id={`enlarge-${index}`}
                      />
                    )}
                    <ImageFrameManipulation
                      is360={is360}
                      width={frameSize.width}
                      height={frameSize.height - margin_frames}
                      src={src}
                      rotation={rotation}
                      onChange={handleChange}
                      top={top}
                      left={left}
                      scale={scale}
                      key={`${type}-image-container`}
                      data-test-id={`${type}-image-container`}
                      id={`${type}-image`}
                      dataBiType={type}
                      onBrightnessChanged={val =>
                        setslidersValues(slidersValues => set(slidersValues, `${type}-image.brightness`, val))
                      }
                      onContrastChanged={val => {
                        setslidersValues(slidersValues => set(slidersValues, `${type}-image.contrast`, val));
                      }}
                      defaultBrightness={get(slidersValues, `${type}-image.brightness`)}
                      defaultContrast={get(slidersValues, `${type}-image.contrast`)}
                    />
                  </div>
                ) : (
                  <div
                    data-test-id={`${type}-image-container`}
                    className={styles.placeholder}
                    style={{ height: frameSize.height, width: frameSize.width + DRAWER_WIDTH + margin_frames }}
                  >
                    <img src={src} alt={`${type}-icon`} />
                  </div>
                )}
              </>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
ImageFramesContainer.defaultProps = {
  width: 360,
  height: 720,
  enlargeWidth: 500,
  enlargeHeight: 720,
  showEnlargeButton: true,
  is360: false,
  rotation: 0,
  verticalSpace: 10
};

ImageFramesContainer.propTypes = {
  /**
   * The conatiner width
   */
  width: PropTypes.number,
  /**
   * The conatiner height
   */
  height: PropTypes.number,
  /**
   * Images sourcesArr
   */
  sourcesArr: PropTypes.array.isRequired,
  /**
   * The conatiner width when enlarged
   */
  enlargeWidth: PropTypes.number,
  /**
   * The conatiner height when enlarged
   */
  enlargeHeight: PropTypes.number,
  /**
   * Enlarged button show/hide
   */
  showEnlargeButton: PropTypes.bool,
  /**
   * The rotation angle to present the images
   */
  rotation: PropTypes.number,
  /**
   * The verticalSpace between images
   */
  verticalSpace: PropTypes.number,
  /**
   * having 360 behaviour
   */
  is360: PropTypes.bool
};

export default ImageFramesContainer;
