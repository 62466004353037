export const IncomingMessagesKeys = {
  TAKE_SCREENSHOTS: 'TAKE_SCREENSHOTS',
  TAKE_SIMULATION_PROGRESS_STATUS: 'TAKE_SIMULATION_PROGRESS_STATUS',
  TIMELAPSE_DATA: 'TIMELAPSE_DATA',
  BINDING_OBJECT_IS_READY: 'BINDING_OBJECT_IS_READY',
  BACK_IN_FOCUS: 'BACK_IN_FOCUS',
  BACK_TO_LANDING: 'BACK_TO_LANDING'
};
export const OutgoingMessagesKeys = {
  SCREENSHOTS_RESULTS: 'SCREENSHOTS_RESULTS',
  OPEN_EXTERNAL_APP: 'OPEN_EXTERNAL_APP',
  START_IOSIM_CALC: 'START_IOSIM_CALC',
  REQUEST_TIMELAPSE_DATA: 'REQUEST_TIMELAPSE_DATA',
  OPEN_EXCEEDED_DOWNLOAD_ATTEMPTS_POPUP: 'OPEN_EXCEEDED_DOWNLOAD_ATTEMPTS_POPUP',
  MODEL_LOADED: 'MODEL_LOADED',
  CONTEXT_CHANGES: 'CONTEXT_CHANGES',
  LOG_MESSAGE: 'LOG_MESSAGE'
};
export const EupPostMessagesKeys = {
  SCREENSHOTS_RESULTS: 'SCREENSHOTS_RESULTS',
  OPEN_EXTERNAL_APP: 'IOSIM_PlUS_BUTTON_CLICKED',
  START_IOSIM_CALC: 'START_IOSIM_PLUS_CALC',
  OPEN_EXCEEDED_DOWNLOAD_ATTEMPTS_POPUP: 'popup_Model_Download_Attempts_Exceeded',
  MODEL_LOADED: 'MODEL_LOADED',
  CONTEXT_CHANGES: 'CONTEXT_CHANGES',
  LOG_MESSAGE: 'LOG_MESSAGE'
};
export const ScannerCallbacksKeys = {
  SCREENSHOTS_RESULTS: 'itero.onScreenshotReady',
  OPEN_EXTERNAL_APP: 'itero.openExternalApp',
  START_IOSIM_CALC: 'itero.startIOSimPlusCalc',
  REQUEST_TIMELAPSE_DATA: 'itero.getEligibleTimeLapseOrdersList',
  CONTEXT_CHANGES: 'itero.contextChanges',
  LOG_MESSAGE: 'itero.logMessage'
};
