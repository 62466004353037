import { isEmpty, isArray } from 'lodash';
import logic from './logger.logic';

const { loggerLogic, getConfig } = logic;

let timers = {};
let _severity;
let _message = '';
let _to = { console: true, host: true };
let _data = {};

const logger = {
  info(message) {
    _message = `INFO : ${message}`;
    _severity = 'info';
    return this;
  },

  log(message) {
    _message = `INFO : ${message}`;
    _severity = 'info';
    return this;
  },

  debug(message) {
    _message = `DEBUG : ${message}`;
    _severity = 'debug';
    return this;
  },

  error(message) {
    _message = `ERROR : ${message}`;
    _severity = 'error';
    return this;
  },

  warn(message) {
    _message = `WARN : ${message}`;
    _severity = 'warn';
    return this;
  },

  to(logTo = 'console') {
    let to = { [logTo]: true };
    if (isArray(logTo)) {
      to = logTo.reduce((acc, key) => ({ ...acc, [key]: true }), {});
    }

    _to = to;

    return this;
  },

  data(data = {}) {
    _data = data;
    return this;
  },

  end() {
    if (!isEmpty(_message) && _severity) {
      loggerLogic(getConfig(), {
        to: _to,
        data: _data,
        message: _message,
        severity: _severity
      });
    } else throw new Error('Message and severity are required');
  },

  time(key) {
    timers[key] = window.performance.now();
  },

  timeEnd(key = 'AppStarted', data = {}) {
    if (!timers[key]) return;
    const timeMs = window.performance.now() - timers[key];
    key !== 'AppStarted' && delete timers[key];
    this.debug(`${key}: ${timeMs}`)
      .to('console')
      .data(data)
      .end();

    return timeMs;
  }
};

export default logger;
