import { isFunction } from 'lodash';
/**
 * This function enables us to use redux middleware more efficiantly
 * middleware - will invoke only if the action.meta.feature === feature
 * goThroughOverride - if exists, will invoke anyway for any action - useful when middlewares should handle actions from different features
 * nextOverride - if exists, will invoke for any action and handle when and if the next will be triggered
 */
const createMiddleware = ({
  feature = '',
  goThroughOverride,
  nextOverride
}) => middleware => store => next => action => {
  const { dispatch, getState } = store;
  const middleWareArgumentsObj = { dispatch, getState, action };
  if (nextOverride) {
    nextOverride({ ...middleWareArgumentsObj, next });
  } else {
    next(action);
  }

  if (isFunction(goThroughOverride)) {
    goThroughOverride(middleWareArgumentsObj);
  }

  if (action.meta && action.meta.feature === feature && isFunction(middleware)) {
    middleware(middleWareArgumentsObj);
  }
};

export default createMiddleware;
