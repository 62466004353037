import { PieCenter } from 'react-pie-menu';
import styled from 'styled-components';

export const StyledPieCenter = styled(PieCenter)`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white !important;
  box-shadow: 1px 1px 32px rgba(0, 0, 0, 0.12), inset 15px 4px 90px rgba(51, 238, 250, 0.12);
`;
