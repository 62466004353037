import { get } from 'lodash';

import * as AT from '../../actionTypes';

const INIT_STATE = {
  draggableObject: {
    active: false
  },
  drawingTool: {
    active: false
  }
};

const match = ({ state, action }) => {
  const { type, payload } = action;

  switch (type) {
    case AT.SET_TOOL_ACTIVE:
      return {
        ...state,
        [payload.name]: { ...state[payload.name], active: payload.state }
      };

    case AT.SET_TOOL_CONFIG:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          config: { ...get(state[payload.name], 'config'), ...payload.config }
        }
      };

    default:
      return state;
  }
};

const featureName = AT.TOOLS;
export default (state = INIT_STATE, action) => {
  const feature = get(action, 'meta.feature');
  return feature === featureName ? match({ state, action }) : state;
};
