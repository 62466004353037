import { endsWith } from 'lodash';
import downloadFile from './download-file/download-file';
import logger from '../logger';
import { getITRModelWithPolling } from './model-fetcher/itr-fetcher.service';
import * as configuration from '../constants/configurationValues.constants';
import { EnvironmentParams } from './../constants';
import { settingsManager } from '../settings-manager';
const {
  EUP_MODEL_URL_PREFIX,
  EUP_NIRI_URL_PREFIX,
  EUP_IS_MODEL_EXIST_URL_PREFIX,
  NIRI_ASSET_REFFERENCE_TYPE_NUMBER
} = EnvironmentParams;

const getNiriFile = async sdk => {
  let niriDataUrl = getNiriFilePath(sdk);
  if (niriDataUrl) {
    return await downloadFile(niriDataUrl, { credentials: 'include' });
  } else {
    logger
      .error('No NIRI file path')
      .data({ module: 'eupRequests' })
      .end();
  }
};

const getBffUrl = () => {
  const serverEndpoint = settingsManager.getConfigValue(configuration.serverEndpoint);
  if (serverEndpoint) {
    return endsWith(serverEndpoint, '/') ? serverEndpoint.substr(0, serverEndpoint.length - 1) : serverEndpoint;
  }
};

const getModelUrl = () => {
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  const fromGenericApi = settingsManager.getConfigValue(configuration.fromGenericApi) === 'true';
  return `${getBffUrl()}${EUP_MODEL_URL_PREFIX}?orderId=${orderId}&fromGenericApi=${fromGenericApi}`;
};

const getNiriFilePath = () => {
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  const fromGenericApi = settingsManager.getConfigValue(configuration.fromGenericApi) === 'true';
  return `${getBffUrl()}${EUP_NIRI_URL_PREFIX}?assetReferenceType=${NIRI_ASSET_REFFERENCE_TYPE_NUMBER}&orderId=${orderId}&fromGenericApi=${fromGenericApi}`;
};

const getItrModel = async () => {
  const modelUrl = getModelUrl();
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  const isModelExistUrl = `${getBffUrl()}${EUP_IS_MODEL_EXIST_URL_PREFIX}?orderId=${orderId}`;
  return await getITRModelWithPolling(modelUrl, isModelExistUrl);
};

export const eupRequests = {
  getNiriFile,
  getModelUrl,
  getItrModel,
  getNiriFilePath
};
