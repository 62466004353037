import { isUndefined } from 'lodash';
import { cacheKeys, cacheManager } from '../cache-manager';
import logger from '../logger';
import * as configValues from '../constants/configurationValues.constants';

const calcMode = modeParameter => {
  let mode = !isUndefined(modeParameter) ? modeParameter.toLowerCase() : modeParameter;
  if (mode !== 'ortho' && mode !== 'resto') {
    if (isUndefined(mode)) {
      logger
        .warn('Query parameter "mode" does not exists. Analyzing case type by url')
        .data({ module: 'settingsManager' })
        .end();
    } else {
      logger
        .warn('Query parameter "mode" does not recognized. Analyzing case type by url')
        .data({ module: 'settingsManager' })
        .end();
    }
    const { pathname } = window.location;
    mode = pathname.toLowerCase().includes('itero') ? 'resto' : 'ortho';
  }

  return mode;
};

export const settingsManager = {
  getConfiguration: () => {
    const urlParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlParams);
  },

  init: () => {
    const configuration = settingsManager.getConfiguration();
    configuration[configValues.mode] = calcMode(configuration[configValues.mode]);
    if (configuration[configValues.isEVxEnabled] === 'true') {
      configuration[configValues.isIOCEnabled] = 'true';
      logger
        .warn('isIOCEnabled changed to "true" because isEVxEnabled = "true"')
        .data({ module: 'settingsManager' })
        .end();
    }
    const lowerCaseConfiguration = Object.keys(configuration).reduce((acc, key) => {
      acc[key.toLowerCase()] = configuration[key];
      return acc;
    }, {});
    cacheManager.set(cacheKeys.CONFIGURATION, lowerCaseConfiguration);

    logger
      .to('host')
      .info(`Configuration keys: \n ${JSON.stringify(lowerCaseConfiguration)}`)
      .data({ module: 'settings manager' })
      .end();
  },
  getConfigValue: value => {
    const config = cacheManager.get(cacheKeys.CONFIGURATION);
    return config ? config[value.toLowerCase()] : undefined;
  }
};
