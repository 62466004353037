import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getModel, logger } from '@web-3d-tool/shared-logic';

function LoadItrFile({ children, url }) {
  const [model, setModel] = useState(null);

  useEffect(() => {
    getModel(url)
      .then(data => {
        !model && setModel(data);
      })
      .catch(() => {
        logger
          .log('cant load file:')
          .data({ url, module: 'LoadItrFile' })
          .end();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return children(model);
}

LoadItrFile.propTypes = {
  /**
   *
   */
  children: PropTypes.func,
  /**
   *
   */
  url: PropTypes.string
};

export default LoadItrFile;
