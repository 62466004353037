import PieMenu from 'react-pie-menu';
import styled from 'styled-components';

export const StyledPieMenu = styled(PieMenu)`
  & ul > li {
    outline: 1px solid #e0edf4;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 115px;
      width: 320px;
      height: 0;
      border-color: #e0edf4;
      border-style: solid;
      border-width: 0 0 4px 4px;
      border-left: 180px solid transparent;
    }
  }
`;
