import * as AT from '../../actionTypes';

export const loadModel = () => {
  return {
    type: AT.LOAD_MODEL,
    payload: {},
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const modelLoaded = ({ model, includesNiriData = false, niriFileData }) => {
  return {
    type: AT.MODEL_LOADED,
    payload: { model, includesNiriData, niriFileData },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const setModelId = ({ id }) => {
  return {
    type: AT.SET_MODEL_ID,
    payload: { id },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const getModel = cb => {
  return {
    type: AT.GET_MODEL,
    payload: { cb },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const getMaterials = cb => {
  return {
    type: AT.GET_MATERIALS,
    payload: { cb },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const setMetadata = ({ metadata }) => {
  return {
    type: AT.SET_METADATA,
    payload: { metadata },
    meta: {
      feature: AT.RENDERER
    }
  };
};
export const setPanorama = ({ panoramaData }) => {
  return {
    type: AT.SET_PANORAMA,
    payload: { panoramaData },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const loadingModelError = ({ model }) => {
  return {
    type: AT.LOADING_MODEL_ERROR,
    payload: { model },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const setTextures = ({ textures }) => {
  return {
    type: AT.SET_TEXTURES,
    payload: { textures },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const setIsThreejsObjectsReady = isThreejsObjectsReady => {
  return {
    type: AT.SET_IS_THREEJS_OBJECTS_READY,
    payload: { isThreejsObjectsReady },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const setRenderingStage = ({ stage }) => {
  return {
    type: AT.SET_RENDERING_STAGE,
    payload: { stage },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const setRenderingReadonlyStage = ({ readonlyStage }) => {
  return {
    type: AT.SET_RENDERING_READONLY_STAGE,
    payload: { readonlyStage },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const imperativeThreeObjectsReady = ({ objects }) => {
  return {
    type: AT.IMPERATIVE_THREE_OBJECTS_READY,
    payload: { objects },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const cameraStoppedMoving = ({ camera, arrayIndex, inArrayIndex }) => {
  return {
    type: AT.CAMERA_STOPPED_MOVING,
    payload: { camera, arrayIndex, inArrayIndex },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const setReadonlyStage = ({ readonlyStage }) => {
  return {
    type: AT.SET_READONLY_STAGE,
    payload: { readonlyStage },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const twoFingersDoubleTap = controls => {
  return {
    type: AT.TWO_FINGERS_DOUBLE_TAP,
    payload: { controls },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const setResetCameraRotationOnUpdate = resetCameraRotationOnUpdate => {
  return {
    type: AT.SET_RESET_CAMERA_ROTATION_ON_UPDATE,
    payload: { resetCameraRotationOnUpdate },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const setStage = stage => {
  return {
    type: AT.SET_STAGE,
    payload: { stage },
    meta: {
      feature: AT.RENDERER
    }
  };
};

export const setModelIsLoading = loading => {
  return {
    type: AT.SET_MODEL_IS_LOADING,
    payload: { loading },
    meta: {
      feature: AT.RENDERER
    }
  };
};
