import * as React from 'react';

const HourglassImage = props => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 66 96" {...props}>
    <defs>
      <linearGradient id="linear-gradient" x1={4} y1={48} x2={62} y2={48} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#f3f3f3" />
        <stop offset={0.5} stopColor="#d9dadb" />
        <stop offset={1} stopColor="#f3f3f3" />
      </linearGradient>
      <linearGradient id="linear-gradient-2" x1={24.3} y1={48} x2={41.7} y2={48} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#e7e7e8" />
        <stop offset={0.5} stopColor="#e4e4e5" />
        <stop offset={1} stopColor="#e7e7e8" />
      </linearGradient>
      <linearGradient id="linear-gradient-3" x1={33} x2={33} y2={12} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#b5d776" />
        <stop offset={1} stopColor="#8ac562" />
      </linearGradient>
      <linearGradient id="linear-gradient-4" y1={84} y2={96} xlinkHref="#linear-gradient-3" />
      <style>{'.cls-7{fill:#399927}.cls-8{fill:#daebba}'}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="UI">
        <path
          d="M12 89.5A7.51 7.51 0 0 1 4.5 82a18.74 18.74 0 0 1 6-12.72L23.16 58.8c3.12-2.58 6.34-6.33 6.34-8.14v-5.32c0-1.81-3.23-5.56-6.34-8.14L10.48 26.72A18.74 18.74 0 0 1 4.5 14 7.51 7.51 0 0 1 12 6.5h42a7.51 7.51 0 0 1 7.5 7.5 18.74 18.74 0 0 1-6 12.72L42.84 37.2c-3.12 2.58-6.34 6.33-6.34 8.14v5.31c0 1.81 3.23 5.56 6.34 8.14l12.68 10.49A18.74 18.74 0 0 1 61.5 82a7.51 7.51 0 0 1-7.5 7.5z"
          fill="url(#linear-gradient)"
        />
        <path
          d="M35.6 45.34c0-1.46 1.8-5 4-7.76l8.24-10.48c2.2-2.81 4-8.7 4-13.1s-2.34-8-5.2-8H19.35c-2.86 0-5.2 3.6-5.2 8s1.8 10.3 4 13.1l8.24 10.49c2.2 2.81 4 6.3 4 7.76v5.31c0 1.46-1.8 5-4 7.76L18.16 68.9c-2.2 2.81-4 8.7-4 13.1s2.34 8 5.2 8h27.3c2.86 0 5.2-3.6 5.2-8s-1.8-10.3-4-13.1l-8.25-10.49c-2.2-2.81-4-6.3-4-7.76z"
          fill="#fff"
        />
        <path
          d="M34.2 45.34a30.76 30.76 0 0 1 1.8-7.75l3.8-10.49A47.36 47.36 0 0 0 41.7 14c0-4.4-1.08-8-2.4-8H26.7c-1.32 0-2.4 3.6-2.4 8a47.36 47.36 0 0 0 1.85 13.1L30 37.59a30.76 30.76 0 0 1 1.85 7.76v5.31A30.76 30.76 0 0 1 30 58.41L26.15 68.9A47.36 47.36 0 0 0 24.3 82c0 4.4 1.08 8 2.4 8h12.6c1.32 0 2.4-3.6 2.4-8a47.36 47.36 0 0 0-1.85-13.1L36 58.41a30.76 30.76 0 0 1-1.85-7.76z"
          fill="url(#linear-gradient-2)"
        />
        <path
          d="M54 7a7 7 0 0 1 7 7 18.46 18.46 0 0 1-5.8 12.33L42.53 36.82C39.28 39.5 36 43.29 36 45.34v5.31c0 2.05 3.28 5.84 6.53 8.53L55.2 69.67A18.46 18.46 0 0 1 61 82a7 7 0 0 1-7 7H12a7 7 0 0 1-7-7 18.46 18.46 0 0 1 5.8-12.33l12.67-10.49C26.72 56.5 30 52.71 30 50.66v-5.32c0-2.05-3.28-5.84-6.53-8.53L10.8 26.33A18.46 18.46 0 0 1 5 14a7 7 0 0 1 7-7h42m0-1H12a8 8 0 0 0-8 8 19.35 19.35 0 0 0 6.16 13.1l12.68 10.49c3.39 2.81 6.16 6.3 6.16 7.76v5.31c0 1.46-2.77 5-6.16 7.76L10.16 68.9A19.35 19.35 0 0 0 4 82a8 8 0 0 0 8 8h42a8 8 0 0 0 8-8 19.35 19.35 0 0 0-6.16-13.1L43.16 58.41c-3.39-2.8-6.16-6.29-6.16-7.75v-5.32c0-1.46 2.77-5 6.16-7.76L55.84 27.1A19.35 19.35 0 0 0 62 14a8 8 0 0 0-8-8z"
          fill="#939598"
        />
        <path
          d="M54 9a5 5 0 0 1 5 5 16.42 16.42 0 0 1-5.08 10.79L41.25 35.28C40 36.28 34 41.47 34 45.34v5.31c0 3.87 6 9.07 7.25 10.07l12.67 10.49A16.42 16.42 0 0 1 59 82a5 5 0 0 1-5 5H12a5 5 0 0 1-5-5 16.42 16.42 0 0 1 5.08-10.79l12.67-10.49C26 59.72 32 54.53 32 50.66v-5.32c0-3.87-6-9.07-7.25-10.07L12.08 24.79A16.42 16.42 0 0 1 7 14a5 5 0 0 1 5-5h42m0-1H12a6 6 0 0 0-6 6 17.31 17.31 0 0 0 5.44 11.56L24.11 36c2.56 2.12 6.89 6.46 6.89 9.3v5.31c0 2.84-4.33 7.18-6.89 9.3L11.44 70.44A17.31 17.31 0 0 0 6 82a6 6 0 0 0 6 6h42a6 6 0 0 0 6-6 17.31 17.31 0 0 0-5.44-11.56L41.89 60C39.33 57.83 35 53.49 35 50.66v-5.32c0-2.84 4.33-7.18 6.89-9.3l12.67-10.48A17.31 17.31 0 0 0 60 14a6 6 0 0 0-6-6z"
          fill="#f3f3f3"
        />
        <path fill="url(#linear-gradient-3)" d="M0 0h66v12H0z" />
        <path
          className="cls-7"
          d="M63 1a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h60m0-1H3a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h60a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3z"
        />
        <path className="cls-8" d="M63 3v6H3V3h60m1-1H2v8h62V2z" />
        <path fill="url(#linear-gradient-4)" d="M0 84h66v12H0z" />
        <path
          className="cls-7"
          d="M63 85a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h60m0-1H3a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h60a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3z"
        />
        <path className="cls-8" d="M63 87v6H3v-6h60m1-1H2v8h62v-8z" />
      </g>
    </g>
  </svg>
);

export default HourglassImage;
