import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Markers from '../Markers';

const ImageMarking = props => {
  const { width, height, src, brightness, contrast, onMarkerCreated } = props;
  const [markers, setMarkers] = useState([]);
  const imageRef = useRef(null);
  const onMouseClick = e => {
    onMarkerCreated([...markers, { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }]);
    setMarkers([...markers, { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }]);
  };

  useEffect(() => {
    imageRef.current.style.filter = `brightness(${brightness}%) contrast(${contrast}%)`;
  }, [brightness, contrast]);

  return (
    <div style={{ width, height }}>
      <Markers markers={markers} onClick />
      <img alt="" ref={imageRef} src={src} width={width} height={height} onClick={onMouseClick} />
    </div>
  );
};

ImageMarking.defaultProps = {
  width: 200,
  height: 200,
  src: '',
  brightness: 100,
  contrast: 100
};
ImageMarking.propTypes = {
  /**
   * The image width
   */
  width: PropTypes.number,
  /**
   * The image width
   */
  height: PropTypes.number,
  /**
   * Image source
   */
  src: PropTypes.string.isRequired,
  /**
   * The number of percentage of brightness apply to the image
   */
  brightness: PropTypes.number,
  /**
   * The number of percentage of contrast apply to the image
   */
  contrast: PropTypes.number,
  /**
   * Callback, fire when button is clicked
   */
  onMarkerCreated: PropTypes.func.isRequired
};

export default ImageMarking;
