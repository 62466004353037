import React from 'react';
import classNames from 'classnames';

import styles from './HtmlLoupe.module.css';

const HtmlLoupe = props => {
  const { shadowEnabled = true } = props;
  const loupeStyle = classNames([styles.loupe, shadowEnabled ? styles.loupeShadow : '']);
  const loupeHandleStyle = classNames([styles.loupeHandle, shadowEnabled ? styles.loupeHandleShadow : '']);
  return (
    <div className={loupeStyle} data-test-id="loupe" id="loupe">
      <div className={loupeHandleStyle}>
        <div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default HtmlLoupe;
