import LocalizedStrings from 'react-localization';
import { logger, settingsManager } from '@web-3d-tool/shared-logic';
import * as configValues from '@web-3d-tool/shared-logic/src/constants/configurationValues.constants';
import en from './translations/en-US.json';

export const strings = new LocalizedStrings({ en });

export const loadLanguage = async () => {
  const localization = settingsManager.getConfigValue(configValues.locale);
  localization &&
    import(`./translations/${localization}.json`)
      .then(newLang => strings.setContent({ newLang }))
      .catch(err =>
        logger
          .error(err)
          .data({ module: 'app shell' })
          .end()
      );
};
