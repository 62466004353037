import * as AT from '../../actionTypes';

export const loadPlugins = ({ initiatingPlugin }) => {
  return {
    type: AT.LOAD_PLUGINS,
    payload: { initiatingPlugin },
    meta: {
      feature: AT.PLUGINS
    }
  };
};

export const clearPlugins = ({ initiatingPlugin }) => {
  return {
    type: AT.CLEAR_PLUGINS,
    payload: { initiatingPlugin },
    meta: {
      feature: AT.PLUGINS
    }
  };
};
