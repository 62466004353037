import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { utils } from '@web-3d-tool/shared-logic';
import PinchZoomPan from '@itero/react-responsive-pinch-zoom-pan-with-rotation';
import { debounce } from 'lodash';
import { logEventToTimber } from './timberWrapper';
import defaultStyles from './ImageFrame.module.css';
import styles360 from './ImageFrame360.module.css';

const ImageFrame = props => {
  const is360 = utils.getIs360HubEnabled();
  const styles = is360 ? styles360 : defaultStyles;

  const { width, height, src, brightness, contrast, rotation, onChange, left, top, scale, id, dataBiType } = props;

  const runOnce = true;
  const inlineStyle = { width, height };
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [imageOriginalSize, setImageOriginalSize] = useState({ width: 0, height: 0 });
  const imageRef = useRef(null);
  const imageDimensionsRef = useRef({
    scale,
    top,
    left
  });

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      const { width, height } = img;
      setImageOriginalSize({ width, height });
      setLoadingComplete(true);
    };
    img.src = src;
  }, [runOnce, src]);

  useEffect(() => {
    const calcImageSize = (containerWidth, containerHeight, imageWidth, imageHeight) => {
      const containerRatio = containerWidth / containerHeight;
      const imageRatio = imageWidth / imageHeight;
      let imgWidth;
      let imgHeight;
      if (containerRatio === imageRatio) {
        imgWidth = containerWidth;
        imgHeight = containerHeight;
      } else if (containerRatio > imageRatio) {
        imgWidth = containerWidth;
        imgHeight = imageHeight * (containerWidth / imageWidth);
      } else {
        imgWidth = imageWidth * (containerHeight / imageHeight);
        imgHeight = containerHeight;
      }

      return { width: imgWidth, height: imgHeight };
    };

    if (loadingComplete) {
      const calculatedSize = calcImageSize(width, height, imageOriginalSize.width, imageOriginalSize.height);
      setImageSize(calculatedSize);
    }
  }, [width, height, loadingComplete, imageOriginalSize.width, imageOriginalSize.height]);

  useEffect(() => {
    imageRef.current.style.filter = `brightness(${brightness}%) contrast(${contrast}%)`;
  }, [brightness, contrast]);

  const debouncedHandlePinchZoomPan = useCallback(
    debounce(args => {
      const { scale: newScale, top: newTop, left: newLeft } = args;
      const { scale: prevScale, top: prevTop, left: prevLeft } = imageDimensionsRef.current;

      let eventType = '';

      const hasNewValuesFromEvent = !(newScale === prevScale && newTop === prevTop && newLeft === prevLeft);

      if (hasNewValuesFromEvent) {
        if (newScale !== prevScale) {
          eventType = 'zoom';
        } else {
          eventType = 'pan';
        }

        logEventToTimber({ eventType, eventSource: dataBiType });

        imageDimensionsRef.current = {
          scale: newScale,
          top: newTop,
          left: newLeft
        };
      }
    }, 1000),
    []
  );

  const handlePinchZoomPanChange = args => {
    if (onChange) {
      onChange(args);
    }

    debouncedHandlePinchZoomPan(args);
  };

  return (
    <div style={inlineStyle} className={styles.container}>
      <PinchZoomPan
        doubleTapBehavior="zoom"
        position="center"
        minScale={1}
        initialScale={1}
        maxScale={10}
        zoomButtons={false}
        debug={false}
        rotation={rotation}
        frameWidth={width}
        frameHeight={height}
        onChange={handlePinchZoomPanChange}
        top={top}
        left={left}
        scale={scale}
      >
        <img
          alt=""
          ref={imageRef}
          src={src}
          width={imageSize.width}
          height={imageSize.height}
          data-test-id={id}
          id={id}
        />
      </PinchZoomPan>
    </div>
  );
};

ImageFrame.defaultProps = {
  width: 200,
  height: 200,
  src: '',
  brightness: 100,
  contrast: 100,
  rotation: 0
};

ImageFrame.propTypes = {
  /**
   * The image width
   */
  width: PropTypes.number,
  /**
   * The image width
   */
  height: PropTypes.number,
  /**
   * Image source
   */
  src: PropTypes.string.isRequired,
  /**
   * The number of percentage of brightness apply to the image
   */
  brightness: PropTypes.number,
  /**
   * The number of percentage of contrast apply to the image
   */
  contrast: PropTypes.number,
  /**
   * The rotation angle of the image
   */
  rotation: PropTypes.number,
  /**
   * Callback, fired when pinch, zoom, pan
   */
  onChange: PropTypes.func,
  /**
   * having 360 style
   */
  is360: PropTypes.bool
};

export default ImageFrame;
