import { Vector3 } from 'three';

import { CameraParams } from './camera-params';

class PZR2DTransform {
  constructor({ scale, rot, additiveTerm }) {
    this.scale = scale;
    this.rot = rot;
    this.additiveTerm = additiveTerm;
  }

  applyToPoint({ x, y }) {
    const { scale, rot, additiveTerm } = this;
    return {
      x: scale * (rot[0][0] * x + rot[0][1] * y) + additiveTerm[0],
      y: scale * (rot[1][0] * x + rot[1][1] * y) + additiveTerm[1]
    };
  }

  applyToVector({ x, y }) {
    const { scale, rot } = this;
    return {
      x: scale * (rot[0][0] * x + rot[0][1] * y),
      y: scale * (rot[1][0] * x + rot[1][1] * y)
    };
  }

  applyToDirection({ x, y }) {
    const { rot } = this;
    return {
      x: rot[0][0] * x + rot[0][1] * y,
      y: rot[1][0] * x + rot[1][1] * y
    };
  }

  getScale() {
    return this.scale;
  }

  inverse() {
    const { scale, rot, additiveTerm } = this;
    return new PZR2DTransform({
      scale: 1 / scale,
      rot: [[rot[0][0], rot[1][0]], [rot[0][1], rot[1][1]]],
      additiveTerm: [
        (-1 / scale) * (rot[0][0] * additiveTerm[0] + rot[1][0] * additiveTerm[1]),
        (-1 / scale) * (rot[0][1] * additiveTerm[0] + rot[1][1] * additiveTerm[1])
      ]
    });
  }
}

class PZRGenerator {
  constructor(
    camera,
    a,
    b,
    minZoom = 0,
    maxZoom = Infinity,
    shouldDenoise = false,
    denoisingFactor = 0.000005,
    denoisingMaxPower = 0.3
  ) {
    this.start = PZRGenerator.extractCoordinates({ a, b });
    this.lastDenoisedPt = { a, b };
    this.denoisingFactor = denoisingFactor;
    this.denoisingMaxPower = denoisingMaxPower;
    this.shouldDenoise = shouldDenoise;
    this.minScale = minZoom / camera.zoom;
    this.maxScale = maxZoom / camera.zoom;
  }

  update(a, b) {
    let pt = this._denoise({ a, b });
    let transform = PZRGenerator.calcTransformFromTwoPointPairs(this.start, PZRGenerator.extractCoordinates(pt));

    if (transform.scale < this.minScale || transform.scale > this.maxScale) {
      pt = this._clipScale(transform.scale, pt);
      transform = PZRGenerator.calcTransformFromTwoPointPairs(this.start, PZRGenerator.extractCoordinates(pt));
    }
    return transform;
  }

  _updateExponentialDenoising({ x: xa, y: ya }, { x: xb, y: yb }, p) {
    return {
      x: xa * p + xb * (1 - p),
      y: ya * p + yb * (1 - p)
    };
  }

  _clipScale(scale, { a, b }) {
    const h = scale < this.minScale ? this.minScale / scale : this.maxScale / scale;
    const c = { x: (a.x + b.x) * 0.5, y: (a.y + b.y) * 0.5 };

    return {
      a: { x: c.x + (a.x - c.x) * h, y: c.y + (a.y - c.y) * h },
      b: { x: c.x + (b.x - c.x) * h, y: c.y + (b.y - c.y) * h }
    };
  }

  _denoise({ a, b }) {
    if (!this.shouldDenoise) return { a, b };

    let denoisingPower = this.denoisingFactor * ((a.x - b.x) * (a.x - b.x) + (a.y - b.y) * (a.y - b.y));

    if (denoisingPower > this.denoisingMaxPower) {
      denoisingPower = this.denoisingMaxPower;
    }

    const a2 = this._updateExponentialDenoising(a, this.lastDenoisedPt.a, denoisingPower);
    const b2 = this._updateExponentialDenoising(b, this.lastDenoisedPt.b, denoisingPower);

    // Fix so that the center point is not denoised.
    const desiredCenter = [(a.x + b.x) / 2, (a.y + b.y) / 2];
    const actualCenter = [(a2.x + b2.x) / 2, (a2.y + b2.y) / 2];
    const offset = [desiredCenter[0] - actualCenter[0], desiredCenter[1] - actualCenter[1]];

    a2.x += offset[0];
    a2.y += offset[1];
    b2.x += offset[0];
    b2.y += offset[1];

    this.lastDenoisedPt = { a: a2, b: b2 };
    return this.lastDenoisedPt;
  }

  static extractCoordinates({ a: { x: xa, y: ya }, b: { x: xb, y: yb } }) {
    const norm = Math.sqrt((xb - xa) * (xb - xa) + (yb - ya) * (yb - ya));
    const dx = xb - xa;
    const dy = yb - ya;

    return {
      xa,
      ya,
      xb,
      yb,
      norm,
      dx,
      dy,
      dxNormalized: norm ? dx / norm : 0,
      dyNormalized: norm ? dy / norm : 0,
      xcenter: (xa + xb) / 2,
      ycenter: (ya + yb) / 2
    };
  }

  static calcTransformFromTwoPointPairs(pair0, pair1) {
    const scale = pair1.norm / pair0.norm;
    let cosTheta = pair1.dxNormalized * pair0.dxNormalized + pair1.dyNormalized * pair0.dyNormalized;

    if (cosTheta > 1.0) {
      cosTheta = 1.0;
    } else if (cosTheta < -1.0) {
      cosTheta = -1.0;
    }

    let theta = Math.acos(cosTheta);
    const crossProduct = pair0.dxNormalized * pair1.dyNormalized - pair0.dyNormalized * pair1.dxNormalized;

    if (crossProduct < 0) {
      theta = -theta;
    }

    const sinTheta = Math.sin(theta);
    const rot = [[cosTheta, -sinTheta], [sinTheta, cosTheta]];
    const additiveTerm = [
      scale * (-rot[0][0] * pair0.xcenter - rot[0][1] * pair0.ycenter) + pair1.xcenter,
      scale * (-rot[1][0] * pair0.xcenter - rot[1][1] * pair0.ycenter) + pair1.ycenter
    ];
    return new PZR2DTransform({ scale, rot, additiveTerm });
  }
}

class PZRApplier {
  constructor(camera, { left, width, top, height }) {
    this.initialCameraParams = new CameraParams(camera);
    this.camera = camera;
    this.screen = { left, width, top, height };
  }

  _applyPZRInWorldCoors([relX, relY], inversePZRTransform) {
    const { left, width, top, height } = this.screen;

    const { x, y } = inversePZRTransform.applyToPoint({
      x: left + relX * width,
      y: top + relY * height
    });

    const inversePZRInWorldCoors = new Vector3((2 * (x - left)) / width - 1, -((2 * (y - top)) / height - 1), 0);
    inversePZRInWorldCoors.applyMatrix4(this.initialCameraParams.unprojectMatrix);
    return inversePZRInWorldCoors;
  }

  _projectVecToPlane(vec) {
    const d = this.initialCameraParams.viewingDirection;
    const alpha = -vec.dot(d);
    vec.addScaledVector(d, alpha);
  }

  _calcUpdatedCamera(pzr2DTransform) {
    const inversePZRTransform = pzr2DTransform.inverse();
    const newPos = this._applyPZRInWorldCoors([0.5, 0.5], inversePZRTransform);
    const offset = new Vector3().subVectors(newPos, this.initialCameraParams.position);
    this._projectVecToPlane(offset);
    const b = this._applyPZRInWorldCoors([0.5, 0], inversePZRTransform);
    const newPosition = this.initialCameraParams.position.clone().add(offset);

    const newTarget = new Vector3().addVectors(newPosition, this.initialCameraParams.viewingDirection);

    return {
      zoom: this.initialCameraParams.zoom * pzr2DTransform.getScale(),
      position: newPosition,
      target: newTarget,
      up: new Vector3().subVectors(b, newPos).normalize()
    };
  }

  updateCamera(pzr2DTransform) {
    const { zoom, position, target, up } = this._calcUpdatedCamera(pzr2DTransform);
    this.camera.zoom = zoom;
    this.camera.updateProjectionMatrix();
    this.camera.position.copy(position);
    this.camera.up = up;
    this.camera.lookAt(target);
  }
}

class PanGenerator {
  constructor(startPoint) {
    this.startPoint = startPoint;
  }

  update(point) {
    return new PZR2DTransform({
      scale: 1.0,
      rot: [[1, 0], [0, 1]],
      additiveTerm: [point.x - this.startPoint.x, point.y - this.startPoint.y]
    });
  }
}

export { PZRGenerator, PZR2DTransform, PZRApplier, PanGenerator };
