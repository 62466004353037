import { chain, get } from 'lodash';
import { postMessageManager } from './post-message-manager';
import { utils } from '../utils';
import { default as logger } from '../logger';
import {
  Environments,
  EupPostMessagesKeys,
  OutgoingMessagesKeys,
  ScannerCallbacksKeys,
  ExternalApps
} from '../constants';
import { eventBus, hostEvents } from '../event-bus';

let pendingScannerMessages = [];

export const formatMessage = message => {
  if (message.key) {
    return message;
  }

  try {
    const obj = JSON.parse(message);
    // handle an old case which the message looks like this: { GET_APP_STATE:'GET_APP_STATE' }
    const key = chain(obj)
      .keys()
      .first()
      .value();

    if (key) {
      return { key, data: {} };
    }

    return null;
  } catch (err) {
    return null;
  }
};

const sendEupMessage = (key, data) => {
  // TODO implement openExternalApp in MIDC for timelapse and iOSim Classic then remove this if's.
  if (key === OutgoingMessagesKeys.OPEN_EXTERNAL_APP && data.app === ExternalApps.IOSIM_CLASSIC) {
    logger
      .warn('MIDC did not implement openExternal app for iOSim Classic')
      .data({ ...data, module: 'host-communication-manager' })
      .end();
    return;
  }
  if (key === OutgoingMessagesKeys.OPEN_EXTERNAL_APP && data.app === ExternalApps.TIMELAPSE) {
    logger
      .warn('MIDC did not implement openExternal app for timelapse')
      .data({ ...data, module: 'host-communication-manager' })
      .end();
    return;
  }
  postMessageManager.send({ key: EupPostMessagesKeys[key], ...data });
  logger
    .info(`calling postMessage with ${data}`)
    .data({ ...data, module: 'host-communication-manager' })
    .end();
};

const sendScannerMessage = (key, payload) => {
  if (window.itero) {
    const callbackId = ScannerCallbacksKeys[key];
    const scannerCallback = get(window, callbackId);
    if (scannerCallback) {
      payload ? scannerCallback(payload) : scannerCallback();
      logger
        .info(payload ? `calling ${callbackId} with message ${payload}` : `calling ${callbackId}`)
        .data({ ...payload, module: 'host-communication-manager' })
        .end();
    } else {
      logger
        .error(`${key} method is not exist`)
        .data({ module: 'host-communication-manager' })
        .end();
    }
  } else pendingScannerMessages.push({ key, payload });
};

export const hostCommunicationManager = {
  initHostCommunicationManager: () => {
    const callback = message => {
      const formattedMessage = formatMessage(message);
      formattedMessage && eventBus.raiseEvent(hostEvents[formattedMessage.key], formattedMessage.data);
    };
    postMessageManager.init(callback);

    eventBus.subscribeToEvent(hostEvents.BINDING_OBJECT_IS_READY, () => {
      pendingScannerMessages.forEach(({ key, payload }) => sendScannerMessage(key, payload));
      pendingScannerMessages = [];
    });
  },
  destroyHostCommunicationManager: () => {
    postMessageManager.destroy();
  },

  sendMessageToHost: (messageKey, payload) => {
    const env = utils.getEnv();
    env === Environments.EUP ? sendEupMessage(messageKey, payload) : sendScannerMessage(messageKey, payload);
  }
};
