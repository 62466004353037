import { get, keys } from 'lodash';
import * as THREE from 'three';

import { PREP_PREFIX, TeethNumbersConstants } from './../constants/model.constants';

let bbox_transformed = null;

export const calcZoomToFitAll = (camera, boundingBox) => {
  // we keep the bbox_transformed matrix as a global variable because the camera.matrixWorldInverse
  // is being changed every time the zoom is being changed and the second time we call this function
  // the zoom has a different value, and we want to return the same value every time.
  bbox_transformed = bbox_transformed || boundingBox.applyMatrix4(camera.matrixWorldInverse);
  const bb_width = bbox_transformed.max.x - bbox_transformed.min.x;
  const bb_height = bbox_transformed.max.y - bbox_transformed.min.y;
  const zoom_x = (camera.right - camera.left) / bb_width;
  const zoom_y = (camera.top - camera.bottom) / bb_height;
  return Math.min(zoom_x, zoom_y);
};

export const getBoundingBox = meshes => {
  const boundingBox = new THREE.Box3();
  meshes.forEach(({ geometry }) => {
    const bbox = geometry.boundingBox;
    bbox && boundingBox.union(bbox);
  });
  return boundingBox;
};

export const getBoundingBoxCenter = boundingBox => {
  const center = new THREE.Vector3();
  boundingBox && boundingBox.getCenter(center);
  return center;
};

export const createMeshsArray = (model, metadata) => {
  if (!model) return null;
  const { objects: geometries } = model;
  return keys({ ...metadata.lower_jaw, ...metadata.upper_jaw }).reduce((acc, key) => {
    const jaw = getJawByObjectKey(key);
    const { visible, material } = get(metadata, `${jaw}.${key}`);
    const geometry = geometries[key];
    const textures = getTexturesForGeometry(key, metadata.textures);
    geometry && geometry.uuid && visible && material && acc.push({ geometry, material, modelName: key, textures });
    return acc;
  }, []);
};
export const createPanorameMesh = (panoramaData, meshes) => {
  if (!panoramaData || !meshes) return null;

  const isLower = meshes[0].modelName.includes('lower');
  const boundingBox = getBoundingBox(meshes);
  const url = panoramaData.blob;
  const bottomRightPoint = panoramaData['bottom-right'];
  const topLeftPoint = panoramaData['top-left'];

  const texture = new THREE.TextureLoader().load(url);

  const material = new THREE.MeshBasicMaterial({
    map: texture,
    transparent: true,
    side: isLower ? THREE.FrontSide : THREE.BackSide
  });

  const width = topLeftPoint.x - bottomRightPoint.x;
  const height = topLeftPoint.y - bottomRightPoint.y;

  const geometry = new THREE.PlaneGeometry(width, height);
  const mesh = new THREE.Mesh(geometry, material);
  const centerOfModel = getBoundingBoxCenter(boundingBox);
  //set position according to center of model (bounding box) and add shift according to panorama center.
  mesh.position.set(
    centerOfModel.x + (topLeftPoint.x + bottomRightPoint.x) / 2,
    centerOfModel.y + (topLeftPoint.y + bottomRightPoint.y) / 2,
    isLower ? boundingBox.max.z : boundingBox.min.z
  );
  return mesh;
};

export const getTexturesForGeometry = (geometryName, textures) => {
  if (!geometryName || !textures || textures.length === 0) return [];
  return textures.reduce((acc, texture) => {
    if (texture.name.includes(`${geometryName}_texture`) || texture.name.includes('occ')) {
      acc.push({ map: texture.texture, name: texture.texture.name, material: texture.material });
    }
    return acc;
  }, []);
};

export const getOpacityForCameraDirection = camera => {
  const vector = new THREE.Vector3();
  camera.getWorldDirection(vector);
  const cos_pow_angle = Math.pow(vector.z, 2) / (Math.pow(vector.x, 2) + Math.pow(vector.y, 2) + Math.pow(vector.z, 2));
  const decay_coeff = 1.3;
  const opacity = cos_pow_angle * decay_coeff;
  return opacity > 1 ? 1 : opacity; // when the angle is too close - we want to keep 1 opacity
};

export const getJawByObjectKey = objectName => {
  if (objectName.includes('lower')) {
    return 'lower_jaw';
  }
  if (objectName.includes('upper')) {
    return 'upper_jaw';
  }
  return parseInt(objectName.replace(PREP_PREFIX, '')) > TeethNumbersConstants.UPPER_JAW_LAST_TEETH_NUMBER
    ? 'lower_jaw'
    : 'upper_jaw';
};
