import { logToTimber } from '@web-3d-tool/shared-logic';

export const logValueChangesUsingTimber = ({ changeType, value, dataBiType }) => {
  let eventName = '';
  let module = '';

  if (dataBiType === 'niri') {
    eventName = changeType === 'brightness' ? 'NIRI brightness - Slide-web viewer' : 'NIRI contrast - Slide-web viewer';
    module = changeType === 'brightness' ? 'NIRI brightness' : 'NIRI contrast';
  } else if (dataBiType === 'color') {
    eventName = changeType === 'brightness' ? 'IOC brightness - Slide-web viewer' : 'IOC contrast - Slide-web viewer';
    module = changeType === 'brightness' ? 'IOC brightness' : 'IOC contrast';
  }

  logToTimber({
    timberData: {
      eventName,
      module,
      action: 'slide',
      type: 'slider',
      value
    }
  });
};
