import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Button.module.css';

const Button = props => {
  const { className: classNameProp, disabled, ...other } = props;

  const className = classNames(styles.button, classNameProp, {
    [styles.disabled]: disabled
  });

  return <button className={className} disabled={disabled} type="button" {...other} />;
};

Button.propTypes = {
  /**
   *
   */
  className: PropTypes.string,
  /**
   * When `true`, button is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Callback, fire when button is clicked
   */
  onClick: PropTypes.func
};

Button.defaultProps = {
  className: null,
  disabled: false
};

export default Button;
