import { combineReducers } from 'redux';

import shell from './feature/shell/shell.reducer';
import renderer from './feature/renderer/renderer.reducer';
import plugins from './feature/plugins/plugins.reducer';
import tools from './feature/tools/tools.reducer';

const reducers = combineReducers({
  shell,
  renderer,
  plugins,
  tools
});

export default reducers;
